import * as React from "react";
import { useRef } from "react";
// import { useDispatch } from 'react-redux'
import Slider from 'react-slick';
import './styles/PhotosModalContent.sass'
interface IMainProps {
    housePhotos: any,
}

const PhotosModalContent: React.FC<IMainProps> = ({ housePhotos }) => {
    const settings = {
        lazyload: true,
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
    };
    console.log(housePhotos)
    // let { handleModal} = React.useContext(ModalContext);
    const sliderRef = useRef(null);
    return (
        <>
            <div className="modal_slide-image-wrap">
                <button
                    className="prewClick"
                    onClick={() => sliderRef.current.slickPrev()}
                >
                    <img src="images/houseslist/icons/prew-icon.svg" alt="назад" />
                </button>
                <button
                    className="nextClick"
                    onClick={() => sliderRef.current.slickNext()}
                >
                    <img src="images/houseslist/icons/next-icon.svg" alt="дальше" />
                </button>
                <Slider {...settings} ref={sliderRef}>
                    {housePhotos.map(function (slide: any, index: number) {
                        return <img className="modal-slider-img" src={slide} alt="дом" key={index}/>;
                    })}
                </Slider>
            </div>
        </>
    );
};

export default PhotosModalContent;

