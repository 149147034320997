
const DateConvert = (startValue: string, endValue: string, startYear: number, endYear: number) => {
    let ResultDates: [string, string] // выходные параметры функции
    console.log(startValue);
    let startDay: string, endDay: string, startMonth: string, endMonth: string; // дни и месяца дат
    // тип "словаря месяцев"
    type MonthOptions = {
        [key: string]: string
    }
    // "словарь" месяцев для переовода месяца в цифровой формат
    let Month: MonthOptions = {
        'января': '01',
        'февраля': '02',
        'марта': '03',
        'апреля': '04',
        'мая': '05',
        'июня': '06',
        'июля': '07',
        'августа': '08',
        'сентября': '09',
        'октября': '10',
        'ноября': '11',
        'декабря': '12'
    };
    // Если первый элемент - число, то его длина строки должна быть меньше длины строки месяца
    if (startValue.split(' ')[0].length < startValue.split(' ')[1].length) {
        startDay = startValue.split(' ')[0];
        startMonth = Month[startValue.split(' ')[1]]; // выбираем месяц и сразу сопостовляес со значением в словаре
    }
    else {
        startDay = startValue.split(' ')[1];
        startMonth = Month[startValue.split(' ')[0]];
    }
    if (endValue.split(' ')[0].length < endValue.split(' ')[1].length) {
        endDay = endValue.split(' ')[0];
        endMonth = Month[endValue.split(' ')[1]];
    }
    else {
        endDay = endValue.split(' ')[1];
        endMonth = Month[endValue.split(' ')[0]];
    }
    ResultDates = [`${startDay}.${startMonth}.${startYear}`, `${endDay}.${endMonth}.${endYear}`];
    return ResultDates;
};

export { DateConvert };
