
import * as React from 'react';
import './styles/ManageSidebar.sass';

type Props = {
    swapSideBarState: boolean
}

const ManageSidebar: React.FC<Props> = ({ swapSideBarState }) => {

    React.useEffect(() => {
        if (swapSideBarState === true) {
            document.getElementById('manage-left-sidebar').className = 'left-sidebar passive'
        }
        else {
            if (document.getElementById('manage-left-sidebar').classList.contains('passive') === true) {
                document.getElementById('manage-left-sidebar').className = 'left-sidebar active'
            }
        }
    }, [swapSideBarState])
    return (
        <>
            <aside className={window.screen.width > 768 ? 'left-sidebar' : 'left-sidebar passive'} id="manage-left-sidebar">
                <div className="user-info">
                    <h3 className="user-name">
                        John Doe
                    </h3>
                    <h4 className="user-email">
                        john.doe@example.com
                    </h4>
                    <button className="user-helper-dropdown"><img src="/images/manageCalendar/sidebar/icons/drowdown.svg" alt="dropdown-toggle" /></button>
                </div>
                <div className="menu">
                    <ul className="menu-list">
                        <li className="menu-list__header"><h3>ОСНОВНОЕ МЕНЮ</h3></li>
                        <li className="menu-list__item"><img src="/images/manageCalendar/sidebar/icons/home.svg" alt="home" /><span>Пользователи</span></li>
                        <li className="menu-list__item"><img src="/images/manageCalendar/sidebar/icons/delicious.svg" alt="widgets" /><span>База домов Казани</span></li>
                        <li className="menu-list__item"><img src="/images/manageCalendar/sidebar/icons/delicious.svg" alt="widgets" /><span>База домов Москвы</span></li>
                        <li className="menu-list__item"><img src="/images/manageCalendar/sidebar/icons/delicious.svg" alt="widgets" /><span>Общая статистика</span></li>
                        <li className="menu-list__item"><img src="/images/manageCalendar/sidebar/icons/delicious.svg" alt="widgets" /><span>Курсы</span></li>
                        <li className="menu-list__item"><img src="/images/manageCalendar/sidebar/icons/delicious.svg" alt="widgets" /><span>Выборка</span></li>
                        <li className="menu-list__item"><img src="/images/manageCalendar/sidebar/icons/delicious.svg" alt="widgets" /><span>Календарь</span></li>
                        <li className="menu-list__item"><img src="/images/manageCalendar/sidebar/icons/delicious.svg" alt="widgets" /><span>Статистика</span></li>
                    </ul>
                </div>
            </aside>
        </>
    );
}

export { ManageSidebar }
