import * as React from "react";
import { useState } from "react";
import useComponentVisible from "../../../../scripts/useComponentVisible.js";
import "./styles/DopDownList.sass"


const options = ["Казань", "Москва", "Волгоград", "Пермь"];

const DropDownList = ({selectedOption, setSelectedOption}) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const toggling = () => setIsComponentVisible(!isComponentVisible);

  const onOptionClicked = value => () => {
    setSelectedOption({...selectedOption, town: value});
    setIsComponentVisible(false);
  };
  
  return (
    <div className="DropDownContainer" ref={ref}>
      <div className="DropDownHeader" onClick={toggling}>
        {selectedOption.town}
      </div>
      {isComponentVisible && (
        <div className="DropDownListContainer">
          <ul className="DropDownList">
            {options.map(option => (
              <li className="ListItem" onClick={onOptionClicked(option)} key={Math.random()}>
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export {DropDownList}