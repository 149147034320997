import * as React from 'react';
import { useEffect, useState } from 'react';
import { getActualMonth, getMonthName, handleNextMonthClick, handlePrewMonthClick } from './CalendarScripts';
import { getDiscountManageCalendar, postBookings, postSpecPrice } from '../../../Actions/ManageCalendarActions';
import { connect } from 'react-redux';
import './styles/ManageCalendarTable.sass';
import { DiscountContent } from './DiscountContent/DiscountContent';

type IBooking = {
    date: string,
    id: number,
    our: boolean,
    status: string
}

interface IActualMonth {
    monthIndex: number,
    Year: number,
    Dates: number[]
}

type IReservedDates = {
    busy: string,
    house: string,
    id: number,
    our_reserve: boolean,
    property: number,
    town: string,
    update_at: string,
}

type IState = {
    discounts: getDiscount[],
    town: string,
    actualMonth: number,
    actualYear: number,
    houses: [{
        age: number,
        bed: number,
        bedroom: number,
        booking_update: string,
        price_comment: string,
        count: number,
        count_budni: number,
        count_weekend: number,
        id: number,
        name: string,
        parameters_model: [{
            id: number,
            parameter: string,
            rus_version: string,
        }], // нужно тоже расписать
        price_budni: number,
        price_weekend: number,
        zalog: string
    }],
}
type getDiscount = {
    day: number,
    five: number,
    four: number,
    house: number,
    house_id: number,
    house_name: string,
    id: number,
    month: number,
    more: number,
    one: number,
    oneweek: number,
    seven: number,
    six: number,
    three: number,
    threeweek: number,
    town: string,
    two: number,
    twoweek: number,
    zero: number
}

type SelectedDate = {
    arrive: string,
    away: string,
}
interface IErrorMessagesState {
    synonym: {
        messageStatus: boolean,
    }
}



interface Props {
    appState: IState,
    filterHousesList: {
        houses: IHouseOriginal,
        discounts?: getDiscount[],
    }
    setAppState: React.Dispatch<React.SetStateAction<IState>>,
    errorMessagesState: IErrorMessagesState,
    setErrorMesagesState: React.Dispatch<React.SetStateAction<IErrorMessagesState>>,
    flagState: boolean,
    setFlagState: React.Dispatch<React.SetStateAction<boolean>>
    reservedDates: IReservedDates[],
    setSelectedDateState: React.Dispatch<React.SetStateAction<SelectedDate>>,
    postBookings(params: IBooking[], access: string): void,
    getDiscountManageCalendar(params: number, access: string): void,
    postSpecPrice(params: object, access: string): void,
    discountByHouse: getDiscount[],
    specPrice: SpecPrice[],
    selectedDateState: SelectedDate,
    tokenState: {
        access: string,
        refresh?: string,
    }
    loggedState: {
        logged: boolean,
    }
};

type IHouseOriginal = [{
    age: number,
    bed: number,
    bedroom: number,
    booking_update: string,
    price_comment: string,
    count: number,
    count_budni: number,
    count_weekend: number,
    id: number,
    name: string,
    parameters_model: [{
        id: number,
        parameter: string,
        rus_version: string,
    }], // нужно тоже расписать
    price_budni: number,
    price_weekend: number,
    zalog: string
}];

type IHouse = {
    age: number,
    bed: number,
    bedroom: number,
    booking_update: string,
    price_comment: string,
    count: number,
    count_budni: number,
    count_weekend: number,
    id: number,
    name: string,
    parameters_model: [{
        id: number,
        parameter: string,
        rus_version: string,
    }] // нужно тоже расписать
    price_budni: number,
    price_weekend: number,
    zalog: string
};

type Discount = {
    show: boolean,
    age: number,
    bed: number,
    bedroom: number,
    price_comment: string,
    count: number,
    count_budni: number,
    count_weekend: number,
    id: number,
    name: string,
    discounts: getDiscount[],
    parameters_model: [{
        id: number,
        parameter: string,
        rus_version: string,
    }] // нужно тоже расписать // нужно тоже расписать
    price_budni: number,
    price_weekend: number,
    zalog: string
}



type SpecPrice = {
    date: string,
    id: number,
    name: string,
    price: string,
    property: number,
    town: string
}

interface PriceState {
    [id: string]: {
        price: number,
        day: number,
    }
}

const ManageCalendarTable: React.FC<Props> = ({ appState, setSelectedDateState, setAppState, errorMessagesState, setErrorMesagesState, flagState, setFlagState, reservedDates, postBookings, getDiscountManageCalendar, discountByHouse, postSpecPrice, specPrice, selectedDateState, filterHousesList, tokenState, loggedState }) => {
    const [actualMonthState, setActualMonthState] = useState<IActualMonth>(getActualMonth(selectedDateState))
    const [discountsState, setDiscountsState] = useState<Discount[]>([]);
    const [specPriceState, setSpecPriceState] = useState<PriceState>({});
    useEffect(() => {
        if (!!filterHousesList.discounts) {
            setActualMonthState(getActualMonth(selectedDateState));
        }
    }, [filterHousesList])
    useEffect(() => {
        setAppState({ ...appState, actualMonth: actualMonthState.monthIndex, actualYear: actualMonthState.Year })
    }, [actualMonthState])
    const skipFilters = () => {
        setErrorMesagesState({
            ...errorMessagesState, synonym: { ...errorMessagesState.synonym, messageStatus: false }
        })
        setSelectedDateState({
            arrive: '',
            away: '',
          })
        setFlagState(() => false);
    }
    useEffect(() => {
        if (specPrice.length > 0) {
            let object = specPrice.reduce((obj, elem) => ({
                ...obj,
                [`${elem.property}.${Number(elem.date.split('-')[2])}`]: {
                    price: Number(elem.price),
                    day: Number(elem.date.split('-')[2])
                }
            }), {})
            setSpecPriceState(object);
        }
    }, [specPrice])
    useEffect(() => {
        if (discountByHouse.length > 0 && discountByHouse.find((disc) => disc.house_id == (discountsState.find((house) => house.id == disc.house_id).id))) {
            const timeDiscountStateArray = [...discountsState];
            // const timeDiscountByHouseArray = [...discountByHouse];
            const discountStateIndex = discountsState.findIndex((house) => house.id == (discountByHouse.find((disc) => house.id == disc.house_id).house_id))
            // const discountByHouseIndex = discountByHouse.findIndex((disc) => disc.house == (discountsState.find((house) => house.id == disc.house_id).id))
            timeDiscountStateArray[discountStateIndex].discounts = [...discountByHouse];
            setDiscountsState(timeDiscountStateArray);
        }
    }, [discountByHouse])

    const changeColorHandler = (event: any, date: Date, id: number): void => {
        const tdDate: string = `${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`;
        // const tdDateForDone: string = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
        const tdId: number = event.target.id;
        // если блок белый
        if (tokenState.access?.length > 0 && loggedState.logged) {
            if (document.getElementById(`${tdId}`).className == 'white' || document.getElementById(`${tdId}`).className == 'saturday white') {
                const timeArray = []
                timeArray.unshift({
                    date: tdDate,
                    id: id,
                    our: false,
                    status: "add"
                })
                postBookings(timeArray, tokenState.access);
                document.getElementById(`${tdId}`).classList.add('black')
                return
            }
            if (document.getElementById(`${tdId}`).className == 'white black' || document.getElementById(`${tdId}`).className == 'saturday white black') {
                const timeArray = [];

                timeArray.unshift({
                    date: tdDate,
                    id: id,
                    our: true,
                    status: "add"
                })
                timeArray.unshift({
                    date: tdDate,
                    id: id,
                    our: false,
                    status: "delete"
                })
                postBookings(timeArray, tokenState.access);
                document.getElementById(`${tdId}`).classList.add('green')
                return
            }
            if (document.getElementById(event.target.id).className == 'white black green' || document.getElementById(`${tdId}`).className == 'saturday white black green') {
                const timeArray = [];
                timeArray.unshift({
                    date: tdDate,
                    id: id,
                    our: true,
                    status: "delete"
                })
                postBookings(timeArray, tokenState.access);

                document.getElementById(`${tdId}`).classList.remove('black', 'green')
                return
            }
        }
        else {
            alert('Вы не вошли')
        }
    }

    const getDiscountByHouse = (id: number, house: IHouse) => {
        if (tokenState.access?.length > 0 && loggedState.logged) {
            if (discountsState.length > 0 && discountsState.find(house => house.id == id)) {
                const timeDiscountArray = [...discountsState];
                const index = discountsState.findIndex(house => house.id == id)
                timeDiscountArray[index] = {
                    show: !discountsState[index].show,
                    id: house.id,
                    age: house.age,
                    bed: house.bed,
                    bedroom: house.bedroom,
                    price_comment: house.price_comment,
                    count: house.count,
                    count_budni: house.count_budni,
                    count_weekend: house.count_weekend,
                    name: house.name,
                    parameters_model: [...house.parameters_model],
                    price_budni: house.price_budni,
                    price_weekend: house.price_weekend,
                    zalog: house.zalog,
                    discounts: [...discountsState[index].discounts],
                }
                setDiscountsState(timeDiscountArray)
            }
            else {
                getDiscountManageCalendar(id, tokenState.access);
                const timeDiscountArray = [...discountsState];
                timeDiscountArray.unshift({
                    show: true,
                    id: house.id,
                    age: house.age,
                    bed: house.bed,
                    bedroom: house.bedroom,
                    price_comment: house.price_comment,
                    count: house.count,
                    count_budni: house.count_budni,
                    count_weekend: house.count_weekend,
                    name: house.name,
                    parameters_model: house.parameters_model,
                    price_budni: house.price_budni,
                    price_weekend: house.price_weekend,
                    zalog: house.zalog,
                    discounts: [],
                })
                setDiscountsState(timeDiscountArray)

            }
        } else {
            alert("Вы не вошли");
        }

    }
    function validate(evt: any) {
        var theEvent = evt;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode(key);
        var regex = /[0-9-]|\./;
        if (!regex.test(key)) {
            theEvent.returnValue = false;
            if (theEvent.preventDefault) theEvent.preventDefault();
        }
    }
    const sendSpecPrice = (id: string, val: string, day: number) => {
        const thisId = Number(id.split('-')[0]);
        const actualDate: string = `${actualMonthState.Year}.${Number(id.split('-')[1])}.${day}`;
        if (tokenState.access?.length > 0 && loggedState.logged) {
            if (val === '-') {
                postSpecPrice({
                    date: actualDate,
                    id: thisId,
                    price: '-'
                }, tokenState.access)
            }
            else if ((Number(val) >= 0) && val != '') {
                postSpecPrice({
                    date: actualDate,
                    id: thisId,
                    price: Number(val)
                }, tokenState.access)
            }
        }
        else {
            alert('Вы не вошли')
        }
    }
    const inputChangeHandler = (e: any, id: number, date: number) => {
        const val = e.target.value;
        if (specPriceState) {
            setSpecPriceState({
                ...specPriceState, [`${id}.${date}`]: {
                    ...specPriceState[`${id}.${date}`],
                    price: val,
                    day: date
                }
            })
        }
    }
    console.log(appState);
    console.log(actualMonthState);
    console.log('reservedDates', reservedDates);
    console.log('specprice', specPriceState);
    console.log('filterHousesList', filterHousesList);
    return (
        <section className="table_section">

            <div className="tableTitle">

                {selectedDateState.arrive.length > 0 && selectedDateState.away.length > 0 ?
                    null :
                    <>
                        <div className="tableTitle__left-side"><button className="prewMonth_button" onClick={() => handlePrewMonthClick(actualMonthState, setActualMonthState, setSpecPriceState)}>Предыдущий</button>
                            <input type="text" disabled value={getMonthName(actualMonthState.Year, actualMonthState.monthIndex)} />
                            <button className="nextMonth_button" onClick={() => handleNextMonthClick(actualMonthState, setActualMonthState, setSpecPriceState)}>Следущий</button>
                        </div>
                        <div className="tableTitle__right-side">
                            <h2>{actualMonthState.Year} год</h2>
                        </div>
                    </>
                }
                {flagState === true ? <button className="removeFilters_button" style={{ backgroundColor: 'white' }} onClick={() => skipFilters()}>Сбросить фильтры</button> : null}
            </div>

            <div className="table-wrapper">
                <table className="manageCalendar_table">
                    <thead>
                        <tr key={0}>
                            <th className="first_column">Дом</th>
                            {(actualMonthState.Dates).map((val: number, index: number) =>
                                <th className="day_number" key={`th.${index}.1`}>{val}</th>
                            )
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {

                            !!appState.houses && appState.houses.length > 0 ?
                                (appState.houses).map((elem, index) =>
                                    <tr key={index + 1}>
                                        <th className="first_column" key={elem.id} onClick={() => getDiscountByHouse(elem.id, elem)} id={`${elem.id}.${actualMonthState.monthIndex}`} >
                                            {elem.name}
                                            {discountsState.length > 0 && discountsState.find(house => house.id == elem.id && house.show === true) ?
                                                <DiscountContent discountsState={discountsState.find(house => house.id == elem.id)} /> : null
                                            }
                                        </th>
                                        {(actualMonthState.Dates).map((date: number, index: number) =>
                                            new Date(actualMonthState.Year, actualMonthState.monthIndex, date).getDay() === 6 ?
                                                reservedDates.find(element => element.property == elem.id && (Number(element.busy.split('-')[2]) == date)) ?
                                                    reservedDates.find(element => element.property == elem.id && (Number(element.busy.split('-')[2]) == date) && !element.our_reserve)  ?
                                                        <td key={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}.1`}><input type="text" className='saturday white black' onKeyPress={(event) => validate(event)} onBlur={(e) => sendSpecPrice(e.target.id, e.target.value, date)} id={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}.1`} onDoubleClick={(e: any) => changeColorHandler(e, new Date(actualMonthState.Year, actualMonthState.monthIndex, date), elem.id)} value={specPriceState && specPriceState[`${elem.id}.${date}`] && specPriceState[`${elem.id}.${date}`].day == date ? specPriceState[`${elem.id}.${date}`].price : ''} onChange={(e) => inputChangeHandler(e, elem.id, date)} /></td> :
                                                        <td key={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}.2`}><input type="text" className='saturday white black green' onKeyPress={(event) => validate(event)} onBlur={(e) => sendSpecPrice(e.target.id, e.target.value, date)} id={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}`} onDoubleClick={(e: any) => changeColorHandler(e, new Date(actualMonthState.Year, actualMonthState.monthIndex, date), elem.id)} value={specPriceState && specPriceState[`${elem.id}.${date}`] && specPriceState[`${elem.id}.${date}`].day == date ? specPriceState[`${elem.id}.${date}`].price : ''} onChange={(e) => inputChangeHandler(e, elem.id, date)} /></td> :
                                                    <td key={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}.3`}><input type="text" className="saturday white" onKeyPress={(event) => validate(event)} onBlur={(e) => sendSpecPrice(e.target.id, e.target.value, date)} id={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}`} onDoubleClick={(e: any) => changeColorHandler(e, new Date(actualMonthState.Year, actualMonthState.monthIndex, date), elem.id)} value={specPriceState && specPriceState[`${elem.id}.${date}`] && specPriceState[`${elem.id}.${date}`].day == date ? specPriceState[`${elem.id}.${date}`].price : ''} onChange={(e) => inputChangeHandler(e, elem.id, date)} /></td>
                                                :
                                                reservedDates.find(element => element.property == elem.id && (Number(element.busy.split('-')[2]) == date)) ?
                                                    reservedDates.find(element => element.property == elem.id && (Number(element.busy.split('-')[2]) == date) && !element.our_reserve) ?
                                                        <td key={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}.4`}><input type="text" className='white black' onKeyPress={(event) => validate(event)} onBlur={(e) => sendSpecPrice(e.target.id, e.target.value, date)} id={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}`} onDoubleClick={(e: any) => changeColorHandler(e, new Date(actualMonthState.Year, actualMonthState.monthIndex, date), elem.id)} value={specPriceState && specPriceState[`${elem.id}.${date}`] && specPriceState[`${elem.id}.${date}`].day == date ? specPriceState[`${elem.id}.${date}`].price : ''} onChange={(e) => inputChangeHandler(e, elem.id, date)} /></td> :
                                                        <td key={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}.5`}><input type="text" className='white black green' onKeyPress={(event) => validate(event)} onBlur={(e) => sendSpecPrice(e.target.id, e.target.value, date)} id={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}`} onDoubleClick={(e: any) => changeColorHandler(e, new Date(actualMonthState.Year, actualMonthState.monthIndex, date), elem.id)} value={specPriceState && specPriceState[`${elem.id}.${date}`] && specPriceState[`${elem.id}.${date}`].day == date ? specPriceState[`${elem.id}.${date}`].price : ''} onChange={(e) => inputChangeHandler(e, elem.id, date)} /></td> :
                                                    <td key={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}.6`} className='white' ><input className="white" onKeyPress={(event) => validate(event)} type="text" onBlur={(e) => sendSpecPrice(e.target.id, e.target.value, date)} id={`${elem.id}-${actualMonthState.monthIndex + 1}-${actualMonthState.Dates[index]}`} onDoubleClick={(e: any) => changeColorHandler(e, new Date(actualMonthState.Year, actualMonthState.monthIndex, date), elem.id)} value={specPriceState && specPriceState[`${elem.id}.${date}`] && specPriceState[`${elem.id}.${date}`].day == date ? specPriceState[`${elem.id}.${date}`].price : ''} onChange={(e) => inputChangeHandler(e, elem.id, date)} /></td>
                                        )}
                                    </tr>

                                )
                                : null
                        }
                    </tbody>
                </table>
            </div>
        </section>
    );
}

const mapStateToProps = (state: any) => {
    return {
        discountByHouse: state.manageCalendarDiscount,
        tokenState: state.token,
        loggedState: state.logged,
    };
};
const mapDispatchToProps = {
    postBookings,
    getDiscountManageCalendar,
    postSpecPrice,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCalendarTable);
