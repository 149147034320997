import * as React from "react";
import { useState, useEffect } from "react";
let myMap;
let myCircle = [];

const YandexMapHousePage = ({ coordState, housePageState }) => {
	// загрузка скриптов
	const loadScript = (src, onLoad) => {
		const script = document.createElement("script");
		script.src = src;
		script.async = true;
		document.body.appendChild(script);
		script.onload = onLoad;
	};
	// инициализация самой карты
	const init = () => {
		// Создаем карту.
		if (document.getElementById("house-map").childNodes) {
			document.getElementById("house-map").childNodes.length == 0 ? (myMap = null) : null;
		}
		console.log("МОЯ карта третьей страницы!!!", myMap);
		if (coordState) {
			const center = [Number(coordState[0]), Number(coordState[1])];
			myMap = new ymaps.Map(
				"house-map",
				{
					center: center,
					zoom: 9.5,
					behaviors: ["default", "scrollZoom"],
				},
				{
					searchControlProvider: "yandex#search",
				}
			);
			myMap.controls.add("zoomControl");
		}
		// Создаем круг.
		if (coordState) {
			myCircle = new ymaps.Circle(
				[
					// Координаты центра круга.
					center,
					// Радиус круга в метрах.
					1500,
				],
				{
					// Описываем свойства круга.
					// Содержимое балуна.
					// Содержимое хинта.
					hintContent: String(housePageState.house.name),
				},
				{
					// Задаем опции круга.
					// Включаем возможность перетаскивания круга.
					// draggable: true,
					// Цвет заливки.
					// Последний байт (77) определяет прозрачность.
					// Прозрачность заливки также можно задать используя опцию "fillOpacity".
					fillColor: "#DB709377",
					// Цвет обводки.
					strokeColor: "#990066",
					// Прозрачность обводки.
					strokeOpacity: 0.8,
					// Ширина обводки в пикселях.
					strokeWidth: 2,
				}
			);

			myMap.geoObjects.add(myCircle);
		}
		// Добавляем круг на карту.
	};
	// вызов функции инициализации карты после прогрузки скрипта
	useEffect(() => {
		console.log("ZAWEL V USEEFFECT V KARTE");
		loadScript(
			"https://api-maps.yandex.ru/2.0/?apikey=d22b8591-7c26-462c-809b-0d4f5f800f17&load=package.full&lang=ru-RU",
			() => {
				window.ymaps.ready(init);
			}
		);
	}, []);

	return <div id="house-map" style={{ width: "100%", height: "100%" }} />;
};

export { YandexMapHousePage };
