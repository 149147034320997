import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { GetAllHousesManageCalendar, getHousesBySynonymManageCalendar, GetReservedDatesManageCalendar, GetSpecPriceManageCalendar } from '../../Actions/ManageCalendarActions';
import { DropDownList } from '../universal/mainThreePages/dropdown-list/DropDownList.jsx';
import { ManageNavbar } from '../universal/manageComponents/manageNavbar/ManageNavbar';
import { ManageSidebar } from '../universal/manageComponents/manageSidebar/ManageSidebar';
// import { FilterDropDownList } from './filterDropDownList/FilterDropDownList';
import ManageCalendarSearchFilters from './manageCalendarSearchFilters/ManageCalendarSearchFilters';
import ManageCalendarTable from './manageCalendarTable/ManageCalendarTable';
import { useCookies } from "react-cookie";
import './styles/ManageCalendar.sass'

type IHouse = [{
  age: number,
  bed: number,
  bedroom: number,
  booking_update: string,
  price_comment: string,
  count: number,
  count_budni: number,
  count_weekend: number,
  id: number,
  name: string,
  parameters_model: [{
    id: number,
    parameter: string,
    rus_version: string,
  }], // нужно тоже расписать
  price_budni: number,
  price_weekend: number,
  zalog: string
}];

type getDiscount = {
  day: number,
  five: number,
  four: number,
  house: number,
  house_id: number,
  house_name: string,
  id: number,
  month: number,
  more: number,
  one: number,
  oneweek: number,
  seven: number,
  six: number,
  three: number,
  threeweek: number,
  town: string,
  two: number,
  twoweek: number,
  zero: number
}

type IReservedDates = {
  busy: string,
  house: string,
  id: number,
  our_reserve: boolean,
  property: number,
  town: string,
  update_at: string,
}

interface IState {
  discounts: getDiscount[],
  town: string,
  actualMonth: number,
  actualYear: number,
  houses: IHouse,
}

type IBooking = {
  date: string,
  id: number,
  our: boolean,
  status: string
}

type SpecPrice = {
  date: string,
  id: number,
  name: string,
  price: string,
  property: number,
  town: string
}

interface ISettingsProps {
  allHousesList: {
    houses: IHouse,
    discounts?: getDiscount[],
  } //whatever may be the datatype.
  filterHousesList: {
    houses: IHouse,
    discounts: getDiscount[],
  }
  reservedDates: IReservedDates[],
  postBookings: IBooking,
  specPrice: SpecPrice[],
  postPrice: any,
  GetAllHousesManageCalendar(params: object, access: string): void,
  getHousesBySynonymManageCalendar(params: string, access: string): void,
  GetReservedDatesManageCalendar(params: object, access: string): void,
  GetSpecPriceManageCalendar(params: object, access: string): void,
  tokenState: {
    access: string,
    refresh?: string,
  }
  loggedState: {
    logged: boolean,
  }
}

type IFilterState = {
  synonym: string,
}

interface IErrorMessagesState {
  synonym: {
    messageStatus: boolean,
  }
}

type SelectedDate = {
  arrive: string,
  away: string,
}

const ManageCalendar: React.FC<ISettingsProps> = ({ allHousesList, filterHousesList, postBookings, reservedDates, GetAllHousesManageCalendar, getHousesBySynonymManageCalendar, GetReservedDatesManageCalendar, specPrice, GetSpecPriceManageCalendar, postPrice, tokenState, loggedState }: ISettingsProps) => {
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = useCookies(["refresh"]);
  const initialHousesState: IState = {
    town: 'Казань',
    discounts: [],
    actualMonth: (new Date().getMonth()),
    actualYear: new Date().getFullYear(),
    houses: [{
      age: null,
      bed: null,
      bedroom: null,
      booking_update: '',
      price_comment: '',
      count: null,
      count_budni: null,
      count_weekend: null,
      id: null,
      name: '',
      parameters_model: [{
        id: null,
        parameter: '',
        rus_version: '',
      }], // нужно тоже расписать
      price_budni: null,
      price_weekend: null,
      zalog: ''
    }],
  }

  const [swapSideBarState, setSwapSideBarState] = useState(() => window.screen.width > 768 ? false : true);
  const [bookingsListState, setBookingsListState] = useState<IReservedDates[]>([]);
  const [selectedDateState, setSelectedDateState] = useState<SelectedDate>({
    arrive: '',
    away: '',
  });
  const [flagState, setFlagState] = useState(() => false); // при изменении флага вызов апи вывода всех домов без фильтров
  const [filterState, setFilterState] = useState<IFilterState>({
    synonym: '',
  });
  const [errorMessagesState, setErrorMesagesState] = useState<IErrorMessagesState>({
    synonym: {
      messageStatus: false,
    }
  })

  const [appState, setAppState] = useState<IState>(initialHousesState);
  // контроль задвижения сайдбара
  React.useEffect(() => {
    if (swapSideBarState === true) {
      document.getElementById('manage-calendar-main').className = 'calendarMain passive'
    }
    else {
      if (document.getElementById('manage-calendar-main').classList.contains('passive') === true) {
        document.getElementById('manage-calendar-main').className = 'calendarMain active'
      }
    }
  }, [swapSideBarState])

  // обновления списка всех домов
  // 1. Сменился город
  // 2. Сменился месяц
  // 3. Убрали фильтры и нужно снова вывести список всех домов
  useEffect(() => {
    if (!!tokenState.access && tokenState.access?.length > 0 && loggedState.logged && !flagState) {
      GetAllHousesManageCalendar({
        town: appState.town,
        month: appState.actualMonth + 1,
        year: appState.actualYear

      }, tokenState.access);
    }

  }, [appState.actualMonth, appState.town, flagState, loggedState.logged]);
  // получения занятых дат !!!!!! НО ОТОБРАЖАТЬ НУЖНО ТОЛЬКО ПОСЛЕ ПОЛУЧЕНИЯ СПИСКА ВСЕХ ДОМОВ !!!!!
  useEffect(() => {
    if (!!tokenState.access && tokenState.access?.length > 0 && loggedState.logged && allHousesList.houses && allHousesList.houses?.length > 0 && !flagState) {
      GetReservedDatesManageCalendar({
        town: appState.town,
        month: appState.actualMonth + 1,
        year: appState.actualYear
      }, tokenState.access)
    }
  }, [allHousesList, appState.town, flagState, postBookings, loggedState.logged])
  // получения спец. цен !!!!!! НО ОТОБРАЖАТЬ НУЖНО ТОЛЬКО ПОСЛЕ ПОЛУЧЕНИЯ СПИСКА ВСЕХ ДОМОВ !!!!!
  useEffect(() => {
    if (!!tokenState.access && tokenState.access?.length > 0 && loggedState.logged && !!allHousesList.houses && allHousesList.houses?.length > 0 && !flagState) {
      GetSpecPriceManageCalendar({
        town: appState.town,
        month: appState.actualMonth + 1,
        year: appState.actualYear
      }, tokenState.access)
    }
  }, [allHousesList, appState.town, flagState, postPrice, loggedState.logged])
  useEffect(() => {
    setBookingsListState([...reservedDates]);
  }, [allHousesList, postBookings, reservedDates])

  // При получении списка всех домов заполняем состояние, которое потом будет передавать в качестве пропсов другим потомкам
  useEffect(() => {
    console.log('allhouseslist', allHousesList);
    console.log('filterHousesList', filterHousesList)
    // if (filterHousesList !== undefined && selectedDateState.arrive.length > 0 && selectedDateState.arrive.length > 0) {
    //   setAppState({ ...appState, houses: filterHousesList.houses, discounts: filterHousesList.discounts });
    // }
    // else
    if (!!allHousesList.houses && !!allHousesList.discounts) {
      console.log('зашёл в первое место место')
      setAppState({ ...appState, houses: allHousesList.houses, discounts: allHousesList.discounts });
    }
    else if (!!allHousesList.houses) {
      console.log('зашёл во второе место')
      setAppState({ ...appState, houses: allHousesList.houses, discounts: [] });
    }
    else {
      setAppState({ ...appState, houses: initialHousesState.houses })
    }
  }, [allHousesList, filterHousesList]);

  // заполнения состояния фильтров через инпуты
  const handleInputChange = (event: any) => {
    const { name, value } = event.currentTarget;
    setFilterState({ ...filterState, [name]: value });
  };

  // обнуления поля синоним, при закрытии фильтров
  useEffect(() => {
    if (!flagState) {
      setFilterState({ ...filterState, synonym: '' });
    }
  }, [flagState])

  // вызов поиска по фильтрам (!!!нужно перенести в отдельную функцию!!!)
  const searchBySynonymClick = (val: string) => {
    console.log(tokenState.access);
    if (val === 'synonym') {
      if (filterState.synonym.length > 0) {
        setErrorMesagesState({
          ...errorMessagesState, synonym: { ...errorMessagesState.synonym, messageStatus: false }
        })
        setFlagState(() => true)
        if (!!tokenState.access && tokenState.access?.length > 0 && loggedState.logged) {
          getHousesBySynonymManageCalendar(filterState.synonym, tokenState.access);
        }
        else {
          alert('Вы не вошли')
        }
      }
      else {
        setErrorMesagesState({
          ...errorMessagesState, synonym: { ...errorMessagesState.synonym, messageStatus: true }
        })
      }
    }
  }

  const tokenLogout = () => {
    console.log('cookies.refresh', cookies.refresh);
    if (cookies.refresh) {
      console.log('зашёл в удалени')
      removeCookie('refresh');
    }
    if (localStorage.getItem('access')) {
      localStorage.removeItem('access');
    }
    dispatch({
      type: "LOGOUT_TOKEN",
    });
    dispatch({
      type: "AUTH_FAILED",
    });
  }
  console.log(flagState);
  return (
    <div className="manageCalendar">
      <ManageNavbar swapSideBarState={swapSideBarState} setSwapSideBarState={setSwapSideBarState} />
      <div className="content">
        <ManageSidebar swapSideBarState={swapSideBarState} />
        <main className="calendarMain" id="manage-calendar-main">
          <div className="title">
            <div className="title__header"><h1></h1></div>
            <div className="title__subheader"><h4>Добро пожаловать домой</h4></div>
            <button onClick={() => tokenLogout()}>Выйти</button>
          </div>
          <div className="searchParameters_wrap">
            <section className="searchParams town_setting">
              <header className="searchParams__title"><h3>Выбор города</h3></header>
              <div className="searchParams__content">
                <span>Город: </span>
                <DropDownList selectedOption={appState} setSelectedOption={setAppState} />
              </div>
            </section>
            <section className="searchParams synonymSearch_setting" id="synonymSearch_setting_section">
              <header className="searchParams__title"><h3>Поиск по синониму</h3></header>
              <div className="searchParams__content searchBySynonym">
                <input type="text" className="searchBySynonym__input" name="synonym" placeholder="Поиск по синониму" value={filterState.synonym}
                  onChange={handleInputChange} />
                <button className="searchBySynonym__button" type="button" onClick={() => searchBySynonymClick('synonym')}>Поиск</button>
              </div>
              {
                errorMessagesState.synonym.messageStatus ?
                  <span style={{ color: 'red', fontSize: '13px' }}>Заполните поле!</span>
                  : null
              }
            </section>
            <section className="searchParams advancedSearch_setting">
              <header className="searchParams__title"><h3>Расширенный поиск</h3></header>
              <div className="searchParams__content">
                <ManageCalendarSearchFilters appState={appState} selectedDateState={selectedDateState} setSelectedDateState={setSelectedDateState} setFlagState={setFlagState} />
              </div>
            </section>
          </div>
          <ManageCalendarTable appState={appState} setAppState={setAppState} errorMessagesState={errorMessagesState} setErrorMesagesState={setErrorMesagesState} flagState={flagState} setFlagState={setFlagState} reservedDates={bookingsListState} specPrice={specPrice} selectedDateState={selectedDateState} filterHousesList={allHousesList} setSelectedDateState={setSelectedDateState}/>
        </main>
      </div>

    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    allHousesList: state.manageCalendar,
    reservedDates: state.manageCalendarReservedDates,
    postBookings: state.manageCalendarBookings,
    specPrice: state.manageCalendarSpecPrice,
    postPrice: state.manageCalendarPostPrice,
    filterHousesList: state.manageFiltersCalendar,
    tokenState: state.token,
    loggedState: state.logged,
  };
};

const mapDispatchToProps = {
  GetAllHousesManageCalendar,
  getHousesBySynonymManageCalendar,
  GetReservedDatesManageCalendar,
  GetSpecPriceManageCalendar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCalendar);
