import * as React from "react";
import { useRef, useState } from "react";
import Slider from "react-slick";
import {
	NavLink,
	Link
} from "react-router-dom";
import { refreshPage } from "../../scripts/refreshPage.js";
import { history } from "../../Store/Store.js";
import { getAllUrlParams } from "../../scripts/getAllUrlParams.js";
const HouseWrapItem = ({house, sp}) => {
    const sliderRef = useRef(null); // ссылка на слайдер
    const [imageState, setImageState] = useState([]); // хранит в себе массив фотографий

	const settings = {
		lazyLoad: true,
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};

    // const routeToHousePage = (id) => {
	// 	console.log("главный стейт ", sp);
	// 	if (!sp.town || !sp.arrive || !sp.away || !sp.people || !sp.hash) return;
	// 	console.log("главный стейт ", sp);
	// 	history.push({
	// 		pathname: '/home',
	// 		search: '?aid='+sp.hash+'&id='+id,
	// 	});
	// 	refreshPage();
	// };
	return (
		<section className="houses__wrap">
			<div className="house-image">
				{window.screen.width <= 768 ? (
					<div className="slide-image-wrap">
						<button className="prewClick" onClick={() => sliderRef.current.slickPrev()}>
							<img src="images/houseslist/icons/prew-icon.svg" alt="" />
						</button>
						<button className="nextClick" onClick={() => sliderRef.current.slickNext()}>
							<img src="images/houseslist/icons/next-icon.svg" alt="" />
						</button>
						<Link to={`/home?aid=${getAllUrlParams().aid}&id=${house.id}`}>
							<Slider {...settings} ref={sliderRef}>
								{house.photo.split(" ").map(function (slide) {
									return <img src={slide} alt="дом" />;
								})}
							</Slider>
						</Link>
					</div>
				) : (
					<div className="slide-image-wrap">
						<button className="prewClick" onClick={() => sliderRef.current.slickPrev()}>
							<img src="images/houseslist/icons/prew-icon.svg" alt="" />
						</button>
						<button className="nextClick" onClick={() => sliderRef.current.slickNext()}>
							<img src="images/houseslist/icons/next-icon.svg" alt="" />
						</button>
						<Slider {...settings} ref={sliderRef}>
							{house.photo.split(" ").map(function (slide) {
								return <img src={slide} alt="дом" />;
							})}
						</Slider>
					</div>
				)}
				<button className="house-image__favorite-icon">
					<img src="images/houseslist/icons/favorite-house-icon.svg" alt="" />
				</button>
				<div className="house-info-name">
					<h2>{house.name}</h2>
				</div>
				<div className="house-info-cost">
					<p>
						{Math.round(Number(sp.price_dict[house.id] / Number(sp.date_range_for_html)))} руб/ночь
					</p>
					<div className="result-cost">всего {sp.price_dict[house.id]} руб</div>
				</div>
				<div className="rating-wrap">
					<img src="/images/houseslist/img/adaptive/star.svg" alt="" />
					<p>4,5</p>
				</div>
			</div>
			<div className="house-info">
				<div className="house-info__wrap">
					<div className="house-info__wrap__name">
						<h2>{house.name}</h2>
					</div>
					<div className="house-info__wrap__cost">
						<p>всего {sp.price_dict[house.id]} рублей</p>
						<div className="result-cost">
							{Math.round(Number(sp.price_dict[house.id] / Number(sp.date_range_for_html)))}{" "}
							руб/ночь
						</div>
					</div>
				</div>

				<div className="house-info__description">
					<p>{house.description}</p>
					<p>...</p>
				</div>
				<div className="house-info__description-btn">
					<button className="button" onClick={() => routeToHousePage(house.id)}>
						Подробнее..
					</button>
				</div>
				<div className="house-info__reservation">
					<Link className="reservation-btn" to={`/home?aid=${getAllUrlParams().aid}&id=${house.id}`}>
						Забронировать
					</Link>
				</div>
			</div>
		</section>
	);
};


export {HouseWrapItem}