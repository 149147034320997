import * as React from "react";
import { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
	BrowserRouter as Router,
	Route,
	Link,
	NavLink,
	useRouteMatch,
	useParams,
	Redirect,
	HashRouter,
	useHistory,
} from "react-router-dom";
import { Footer } from "../universal/mainThreePages/footer/Footer.jsx";
import "./styles/main.sass";
import PropTypes from "prop-types";
import momentPropTypes from "react-moment-proptypes";
import * as moment from "moment";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import "./styles/main-date.sass";
import { postFirstPage } from "../../Actions/Actions.js";
import { DateConvert } from "../universal/mainThreePages/date-convert/DateConvert.tsx";
import { history } from "../../Store/Store.js";
import { getHashCode } from "../../scripts/getHashCode.tsx";
import { DropDownList } from "../universal/mainThreePages/dropdown-list/DropDownList.jsx";
import "../../../public/fonts.css";
import NavMenu from "../universal/mainThreePages/nav-menu/NavMenu.jsx";

const MainPage = () => {
	const dispatch = useDispatch();
	let order;
	let s;
	let hashCode;
	moment.locale("ru");
	let [firstPageStatus, setFirstPageStatus] = useState();
	const [modalActive, setModalActive] = useState();
	const [sign, setSign] = useState();
	const [startDate, setStartDate] = React.useState();
	const [endDate, setEndDate] = React.useState();
	const [focusedInput, setFocusedInput] = React.useState();
	const [peopleError, setPeopleError] = useState(false);
	const [dateError, setDateError] = useState(false);
	const dateFormat = "DD MMMM";
	const dateOfArrive = document.getElementById("start-date"); // arrive date
	const dateOfAway = document.getElementById("end-date"); // away date
	let mainStartDay;
	let mainEndDay;
	// главное состояние текущего компонента
	const [appState, setAppState] = useState({
		town: "Казань", // указаны изначально
		arrive: "", // заезд формат 05.08.2021
		away: "", // выезд формат 06.08.2021
		people: 5, // указано изначально
		parameters: "",
		page: 1,
		hash: "",
	});
	const [navMenuState, setNavMenuState] = useState();
	// open filter
	const _onButtonClick= () => {
		setNavMenuState(true);
	};
	// заполнение города и кол-ва человек
	const handleInputChange = (event) => {
		const { name, value } = event.currentTarget;
		setAppState({ ...appState, [name]: value });
	};

	//Формируется запрос, добавляется дата и генерируется хэш код.
	const fillParams = () => {
		const mainDate = DateConvert(dateOfArrive.value, dateOfAway.value, new Date(startDate).getFullYear(), new Date(endDate).getFullYear());
		mainEndDay = String(mainDate[1]);
		mainStartDay = String(mainDate[0]);
		order =
			"заезд " + mainEndDay + " выезд " + mainStartDay + " вместимость " + String(appState.people);
		s = order + new Date();
		hashCode = getHashCode(s);
		if (dateOfArrive.value && dateOfAway.value) {
			setAppState({ ...appState, arrive: mainStartDay, away: mainEndDay, hash: hashCode });
		}
	};

	firstPageStatus = useSelector((state) => state.firstPage);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (appState.people < 1) {
			setPeopleError(true);
			return;
		}
		setPeopleError(false);
		if (!appState.arrive && !appState.away) {
			console.log(appState.arrive, appState.away);
			setDateError(true);
			return;
		}
		setDateError(false);
		if (!appState.town || !appState.arrive || !appState.away || !appState.people || !appState.hash)
			return;
		dispatch(postFirstPage(appState));
	};

	useEffect(() => {
		if (firstPageStatus) {
			if (firstPageStatus.status == 200 && appState.hash) {
				console.log("отправляю запрос на второй сервер!");
				console.log("хэш: ", appState.hash);
				console.log("статус: ", firstPageStatus);
				history.push({
					pathname: "/homes",
					search: "?aid=" + appState.hash,
					state: { hash: appState.hash },
				});
			}
		}
	}, [firstPageStatus]);

	return (
		<>
			<div className="mainpage-wrap">
				<div className="navbar">
					<div className="container">
						<div className="nav-logo">
							<img src="images/house-page/adaptive/nav-logo.png" alt="" />
						</div>
						<ul className="nav-links">
							<li className="nav-links_item">О нас</li>
							<li className="nav-links_item"> Партнерам</li>
							<li className="nav-links_item">Помощь</li>
						</ul>
						<ul className="helpWrap">
							<li className="favorite-icon helpWrap__icons" data-title="избранные">
								<a href="#">
									<img src="images/mainpage/icon/navbar/favorite.svg" alt="favorite" />
								</a>
							</li>
							<li className="notification-icon helpWrap__icons" data-title="уведомления">
								<a href="#">
									<img src="images/mainpage/icon/navbar/notification.svg" alt="notification" />
								</a>
							</li>
							<li className="post-icon helpWrap__icons" data-title="добавить дом">
								<a href="#">
									<img src="images/mainpage/icon/navbar/post.svg" alt="post" />
								</a>
							</li>
						</ul>
						<div className="user-button">
							{/* onClick={() => _onButtonClick()} */}
							<button className="user-button__btn"  onClick={() => _onButtonClick()}>
								<div className="user-wrap">
									<img
										src="images/mainpage/icon/navbar/dividers.svg"
										alt="dividers"
										className="divIcon"
									/>
									<img
										src="images/mainpage/icon/navbar/user-icon.svg"
										alt="user"
										className="userIcon"
									/>
								</div>
							</button>
							{navMenuState ? (
								<NavMenu
									setActive={setNavMenuState}
									setModalActive={setModalActive}
									setSign={setSign}
								/>
							) : null}
						</div>
					</div>
				</div>
				<div className="main mainpage">
					<div className="container">
						{/* background images */}
						<img src="/images/mainpage/img/main/background/logo.png" alt="" className="bg-logo" />
						<img
							src="/images/mainpage/img/main/background/architecture.png"
							alt=""
							className="bg-arch"
						/>
						{window.screen.width > 768 ? (
							<ul className="main-titles">
								<li className="lodging main-titles__spec">Жильё</li>
								<li className="entertainment main-titles__spec">Развлечения</li>
								<li className="community main-titles__spec">Сообщество</li>
							</ul>
						) : null}

						<div className="main-wrap">
							<div className="main-wrap__mobile-header">
								<h1>Vtripe Galactic</h1>
							</div>
							<div className="main-intro">
								<img
									src="/images/mainpage/img/main/background/logo.png"
									alt=""
									className="bg-logo"
								/>
								<h2 className="main-intro__article descr">Все для отдыха в одном месте</h2>
								<p className="main-intro__paragraph descr">c нами быстрее</p>
							</div>
							<form className="main-card" onSubmit={handleSubmit}>
								<div className="main-card__header">
									<h2>Поиск жилья</h2>
								</div>
								<div className="form-group town">
									<label htmlFor="">Местоположение</label>
									<DropDownList selectedOption={appState} setSelectedOption={setAppState} />
								</div>
								<div className="form-group date-form">
									<label htmlFor="" className="first-label-date">
										Прибытие
									</label>
									<label htmlFor="" className="second-label-date">
										Выезд
									</label>
									<DateRangePicker
										startDate={startDate}
										startDateId="start-date"
										endDate={endDate}
										endDateId="end-date"
										onDatesChange={({ startDate, endDate }) => {
											setStartDate(startDate);
											setEndDate(endDate);
										}}
										focusedInput={focusedInput}
										onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
										startDatePlaceholderText={"Когда?"}
										endDatePlaceholderText={"Когда?"}
										noBorder={true}
										displayFormat={dateFormat}
										required={false}
										readOnly={true}
										numberOfMonths="3"
									/>
								</div>
								<div className="form-group">
									<label htmlFor="">Кол-во человек</label>
									<input
										min="1"
										required
										type="number"
										name="people"
										id="people-number"
										placeholder="Кто едет?"
										value={appState.people}
										onChange={handleInputChange}
									/>
								</div>
								<div className="form-group btn">
									<button
										type="submit"
										to={`/houseslist`}
										className="form-group__btn"
										onClick={() => fillParams()}
									>
										Найти
									</button>
								</div>
								{peopleError ? (
									<div className="error-info">
										<p>Кол-во человек не может быть меньше одного</p>
									</div>
								) : dateError ? (
									<div className="error-info">
										<p>Выберите дату</p>
									</div>
								) : null}
							</form>
						</div>
						<div className="useful-link">
							<button className="useful-link__btn" type="submit" value="Полезности тут">
								Полезности тут
							</button>
						</div>
					</div>
					<div className="partnership-link">
						<button className="partnership-link__btn" type="submit" value="Стать">
							Стать партнером
						</button>
					</div>
				</div>
				<Footer />
			</div>
		</>
	);
};

export { MainPage };
