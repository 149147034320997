import * as React from "react";
import { useEffect } from "react";
import {
	Switch,
	Route,
	Redirect,
} from "react-router-dom";
import "../styles/App.css";
import { useSelector, useDispatch } from "react-redux";
import { MainPage } from "./../components/mainpage/MainPage.jsx";
import HousesList from "./../components/houseslist/HousesListPage.jsx";
import HousePage from "./../components/housepage/HousePage.jsx";
import SignUp from "../components/universal/mainThreePages/sign-up/SignUp.tsx";
import SignIn from "../components/universal/mainThreePages/sign-in/SignIn.tsx";
import { withCookies } from "react-cookie";
// import { UserPersonalAccount } from "../components/user-personal-account/UserPersonalAccount.jsx";
import ManageCalendar from "../components/manageCalendar/ManageCalendar";
import { useCookies } from "react-cookie";
import { refreshToken } from "../Actions/RegistrationAction";
import { ModalProvider } from "../components/modal/modalContext/ModalContext";
const App = () => {
	const [cookies] = useCookies(["refresh"]);
	const dispatch = useDispatch();
	const loggedStatus = useSelector((state) => state.logged); // статус залогиненности
	const tokenState = useSelector((state) => state.token); // токен залогиненности

	useEffect(() => {
		if (!loggedStatus.logged && !!cookies.refresh && tokenState.access?.length === 0) {
			console.log("Рефреш имеется, но юзер не залогинен, поэтому попробуем зарефрешить");
			dispatch(refreshToken(cookies.refresh));
		} else if (
			loggedStatus.logged === null &&
			!cookies.refresh &&
			tokenState.access?.length === 0
		) {
			dispatch({
				type: "AUTH_FAILED",
			});
		}
	}, [tokenState, cookies.refresh, loggedStatus.logged]);
	return (
		<>
			<Switch>
				<Route exact path="/" component={() => <MainPage />} />
				<Route exact path="/homes" component={() => <HousesList />} />
				{/* <Route exact path="/1i3y4tgqrui73ry1" component={() => <ManageCalendar />} /> */}
				<Route exact path="/sign_in" component={() => <SignIn />} />
				<Route exact path="/sign_up" component={() => <SignUp />} />
				<Route exact path="/1i3y4tgqrui73ry1">
					{loggedStatus.logged === false ? (
						<Redirect to={{ pathname: "/sign_in", state: "/1i3y4tgqrui73ry1" }} />
					) : (
						<ManageCalendar />
					)}
				</Route>
				<ModalProvider>
					<Route exact path="/home" component={() => <HousePage />} />
				</ModalProvider>
			</Switch>
		</>
	);
};

export default withCookies(App);
