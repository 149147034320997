import * as React from "react";
import { useState, useEffect, useRef } from "react";
import {
	BrowserRouter,
	Switch,
	Route,
	Link,
	NavLink,
	useRouteMatch,
	useParams,
	Redirect,
	HashRouter,
} from "react-router-dom";
import { connect } from "react-redux";
import Switch from "react-switch";
import { postLogout } from "../../../../Actions/Actions";
import "./styles/nav-menu.sass";

const NavMenu = ({ setActive, setModalActive, setSign, logoutRed, loggedStatus }) => {
	useEffect(() => {
		console.log(logoutRed);
	}, [logoutRed]);
	const [checked, setChecked] = useState(false);
	const handleChange = () => {
		setChecked((check) => !check);
	};
	const signupClick = () => {
		setSign(true);
		setModalActive(true);
		setActive(false);
	};
	const signinClick = () => {
		setSign(false);
		setModalActive(true);
		setActive(false);
	};
	function useOutsideAlerter(ref) {
		useEffect(() => {
			/**
			 * Alert if clicked on outside of element
			 */
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setActive(false);
				}
			}

			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);
	return (
		<div className="nav-menu" ref={wrapperRef}>
			<div className="exit-icon">
				<button className="exit-btn" onClick={() => setActive(false)}>
					<img src="/images/navbar/exit-icon.svg" alt="закрыть" />
				</button>
			</div>
			<ul className="nav-menu-list">
				<li className="nav-menu-item">
					{loggedStatus ? (
						<a href="/profile/main">Личный кабинет</a>
					) : (
						<a href="/accounts/login">Войти в личный кабинет</a>
					)}
					{/* <NavLink to={`/sign_in`} className="nav-menu-item__btn">
							Войти в личный кабинет
						</NavLink> */}
				</li>
				<li className="nav-menu-item">
					<a href="/accounts/login">Зарегистрироваться</a>
					{/* <NavLink to={`/sign_up`} className="nav-menu-item__btn">
						Зарегистрироваться
					</NavLink> */}
					{/* <button onClick={() => signupClick()}>Зарегистрироваться</button> */}
				</li>
				<li className="nav-menu-item">
					<a href="#">Помощь</a>
				</li>
				<li className="nav-menu-item">
					<a href="/accounts/logout">Выйти</a>
					{/* <button onClick={postLogout()}>Выйти</button> */}
				</li>
			</ul>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		logoutRed: state.logoutRed,
		loggedStatus: state.logged.logged,
	};
};
const mapDispatchToProps = {
	postLogout,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavMenu);
