import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	NavLink,
	useRouteMatch,
	useParams,
	Redirect,
	HashRouter,
	useHistory,
} from "react-router-dom";
import moment from "moment";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import NavMenu from "../nav-menu/NavMenu.jsx";
import { history } from "../../../../Store/Store.js";
import "./styles/navbar.sass";
import "./styles/nav-date.sass";
import { DateConvert } from "../date-convert/DateConvert.tsx";
import { getHousesListWithFilters } from "../../../../Actions/Actions.js";
import { getHashCode } from "../../../../scripts/getHashCode.tsx";
import { DropDownList } from "../dropdown-list/DropDownList.jsx";
const Navbar = ({ navbarAppState, setNavbarAppState, startPrice, finishPrice, toSecondPage, getHousesListWithFilters }) => {
	moment.locale("ru");
	const dispatch = useDispatch()
	const [navMenuState, setNavMenuState] = useState();
	// open filter
	const _onButtonClick1 = () => {
		setNavMenuState(true);
	};
	let order;
	let s;
	let hashCode;
	const [mainNavbarState, setMainNavbarState] = useState(() => navbarAppState ? navbarAppState.hash : null);
	const [modalActive, setModalActive] = useState();
	const [sign, setSign] = useState();
	const [startDate, setStartDate] = React.useState();
	const [endDate, setEndDate] = React.useState();
	const [focusedInput, setFocusedInput] = React.useState();
	const [flag, setFlag] = useState(() => false);
	const dateFormat = "DD MMMM";
	const dateOfArrive = document.getElementById("start-date"); // arrive date
	const dateOfAway = document.getElementById("end-date"); // away date
	let mainStartDay;
	let mainEndDay;
	console.log(flag);
	const loading = useSelector((state) => state.loadingStatus.loading);
	useEffect(() => {
		if (navbarAppState) {
		if (navbarAppState.arrive != "" && navbarAppState.away != "" && flag == false && loading == false) {
				console.log("navbarAppState!!!!!!!!!!!!!!!!", navbarAppState);
				const newStartDate = moment(new Date(navbarAppState.arrive));
				const newEndDate = moment(new Date(navbarAppState.away));
				setStartDate(newStartDate);
				setEndDate(newEndDate);
				setFlag(true);
			}
						
		}
	}, [])
	useEffect(() => {
		if (dateOfArrive && dateOfAway && navbarAppState) {
			if (dateOfArrive.value && dateOfAway.value) {
		const mainDate = DateConvert(dateOfArrive.value, dateOfAway.value, new Date(startDate).getFullYear(), new Date(endDate).getFullYear());
		mainEndDay = String(mainDate[1])
		mainStartDay = String(mainDate[0])
		setNavbarAppState({...navbarAppState, arrive: mainStartDay, away: mainEndDay})
			}
		}
	}, [startDate, endDate])
	if (toSecondPage) {
		if (String(toSecondPage) == '000') {
			const backRefresh = () => {
				history.push({
					pathname: '/',
				});
			}
		}
		else {
		const backRefresh = () => {
			history.push({
				pathname: '/homes',
				search: '?aid='+toSecondPage,
			});
		}
	}
}
else
{
	const backRefresh = () => {
		history.goBack();
		setTimeout(() => {
			window.location.reload(false);
		}, 500);
	}
} 
	const handleInputChange = (event) => {
		const { name, value } = event.currentTarget;
		setNavbarAppState({ ...navbarAppState, [name]: value });
	};
	const fillParams = () => {	
		console.log("fillparams")
		console.log(navbarAppState);
		const mainDate = DateConvert(dateOfArrive.value, dateOfAway.value, new Date(startDate).getFullYear(), new Date(endDate).getFullYear());
		mainEndDay = String(mainDate[1])
		mainStartDay = String(mainDate[0])
		order = "заезд "+mainEndDay+" выезд "+mainStartDay+" вместимость "+ String(navbarAppState.people);
		s = order + new Date();
		hashCode = getHashCode(s)
		console.log(hashCode);
		if (Number(startPrice) >= 0 && Number(finishPrice) >= 0)
		{
			setNavbarAppState({...navbarAppState, arrive: mainStartDay, away: mainEndDay, hash: String(hashCode), amount: "от " +Number(startPrice)+ " до "+Number(finishPrice)})
		}
		else {
			setNavbarAppState({...navbarAppState, arrive: mainStartDay, away: mainEndDay, hash: String(hashCode), amount: ""})
		}
		console.log("главный стейт ", navbarAppState);
	}
	
	const handleSubmit = (event) => {
		event.preventDefault();
		if (navbarAppState.hash != "" && navbarAppState.arrive && navbarAppState.away) {
			console.log("Отправляю:!!!!!", navbarAppState)
			getHousesListWithFilters(navbarAppState);
			history.push({
				pathname: '/homes',
				search: '?aid='+navbarAppState.hash,
			});
		};
	};
	return (
		<>

			<div className="Navbar">
				<div className="container">
					<div className="nav-logo">
						<img src="images/house-page/adaptive/nav-logo.png" alt="" />
					</div>
					{navbarAppState ?
					<NavLink className="back-btn" to="/">
						<img src="/images/navbar/back-icon.svg" alt="назад" />
						<img src="/images/navbar/adaptive/back-icon.svg" alt="назад" />
					</NavLink>
					: <button className="back-btn" onClick={() => backRefresh()}>
						<img src="/images/navbar/back-icon.svg" alt="назад" />
						<img src="/images/navbar/adaptive/back-icon.svg" alt="назад" />
					</button> }

					<ul className="nav-links">
						<li className="nav-links_item">О нас</li>
						<li className="nav-links_item"> Партнерам</li>
						<li className="nav-links_item">Помощь</li>
					</ul>
					<ul className="logo-wrap">
						<li className="logo">
							<NavLink to="/">
									<img src="/images/navbar/logotype.png" alt="logotype" />
							</NavLink>
						</li>
					</ul>
					{navbarAppState ? 
						navbarAppState.length != 0 ? (
						<form className="navbar-card-wrap" onSubmit={handleSubmit}>
							<div className="first-group-wrap">
								<div className="form-group town">
									<label htmlFor="">Местоположение</label>
									<DropDownList selectedOption={navbarAppState} setSelectedOption={setNavbarAppState}/>
									{/* <select name="town" value={navbarAppState.town} onChange={handleInputChange}>
										<option className="option" value="Казань">Казань</option>
										<option  className="option" value="Москва">Москва</option>
									</select> */}
								</div>
								<div className="form-group guest">
									<label htmlFor="">Кол-во гостей</label>
									<input
										min="1"
									required
										type="text"
										name="people"
										id="town"
										placeholder="2 чел"
										value={navbarAppState.people}
										onChange={handleInputChange}
									/>
								</div>
							</div>

							<div className="form-group second-group">
								<label htmlFor="">Прибытие</label>
								<label htmlFor="">Заезд</label>
								<DateRangePicker
									startDate={startDate}
									startDateId="start-date"
									endDate={endDate}
									endDateId="end-date"
									onDatesChange={({ startDate, endDate }) => {
										setStartDate(startDate);
										setEndDate(endDate);
									}}
									focusedInput={focusedInput}
									onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
									startDatePlaceholderText={"Когда?"}
									endDatePlaceholderText={"Когда?"}
									noBorder={true}
									displayFormat={dateFormat}
									required={false}
									readOnly={true}
								/>
							</div>
							<div className="form-group adapt-hide">
								<label htmlFor="">Кол-во гостей</label>
								<input
									min="1"
									required
									type="text"
									name="people"
									id=""
									placeholder="2 чел"
									value={navbarAppState.people}
									onChange={handleInputChange}
								/>
							</div>
							<div className="form-group">
								<button type="submit" className="form-group__btn" onClick={() => fillParams()}>
									Найти
								</button>
							</div>
						</form>
					) : null : null }
					<ul className="helpWrap">
						{/* <li className="comment">
							<a href="#">Отзывы</a>
						</li> */}
						<li className="favorite-icon helpWrap__icons" data-title="избранные">
							<a href="#">
								<img src="/images/navbar/favorite.svg" alt="favorite" />
								<img src="/images/navbar/adaptive/liked.svg" alt="favorite" />
							</a>
						</li>
						<li className="notification-icon helpWrap__icons" data-title="уведомления">
							<a href="#">
								<img src="/images/navbar/notification.svg" alt="notification" />
								<img src="/images/navbar/adaptive/notification.svg" alt="notification" />
							</a>
						</li>
						<li className="post-icon helpWrap__icons" data-title="добавить дом">
							<a href="#">
								<img src="/images/navbar/post.svg" alt="post" />
								<img src="/images/navbar/adaptive/post.svg" alt="post" />
							</a>
						</li>
						<div className="user-button">
						{/*   */}
							<button className="user-button__btn" onClick={() => _onButtonClick1()}>
								<div className="user-wrap">
									<img src="/images/navbar/dividers.svg" alt="dividers" className="divIcon" />
									<img
										src="/images/navbar/adaptive/dividers.svg"
										alt="dividers"
										className="divIcon"
									/>
									<img src="/images/navbar/user-icon.svg" alt="user" className="userIcon" />
								</div>
							</button>
							{navMenuState ? (
								<NavMenu
									setActive={setNavMenuState}
									setModalActive={setModalActive}
									setSign={setSign}
								/>
							) : null}
						</div>
					</ul>
				</div>
				<button className="liked-btn">
					<img className="liked-icon" src="/images/navbar/adaptive/liked.svg" alt="favorite" />
				</button>
			</div>
		</>
	);
};

const mapDispatchToProps = {
	getHousesListWithFilters
};

export default connect(null, mapDispatchToProps)(Navbar);
