const SecondPageReducer = (housesList = [], action) => {
	switch (action.type) {
		case "GET_HOUSES":
			return action.housesList.data;
		case "GET_FILTERS":
			return action.housesList.data;
		default:
			return housesList;
	}
};

export { SecondPageReducer };