
const getHashCode = (value: string) => {
    let hash: number = 0;

    if (value.length === 0) return hash; // если пустая строка, возвращаем 0

    for (let i = 0; i < value.length; i++) {

        let charCode = value.charCodeAt(i);
        hash = (hash << 7) - hash + charCode;
        hash = hash & hash;

    }
    return hash;
}
export { getHashCode }
