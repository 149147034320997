const FirstPageReducer = (firstPage = [], action) => {
	switch (action.type) {
		case "GET_FIRST_PAGE":
			console.log(action.firstPage)
			return action.firstPage;
		default:
			return firstPage;
	}
};

export { FirstPageReducer };