import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { postRegistration } from '../../../../Actions/RegistrationAction';
import { ChangeNumbers } from '../../../../scripts/phone-number-filling.js';
import { history } from '../../../../Store/Store.js';
import { CheckMailValid } from './signUpScripts/CheckMail';
import './styles/sign-up.sass';

type SignUpState = {
	login: string,
	mail: string,
	firstPassword: string,
	secondPassword: string,
}


type PostRegistrationType = {
	username: string,
	password: string,
	email: string,
	phone: string
}

type tplotOptions = {
	[key: string]: boolean
}

type Props = {
	registrationStatus: any,
	registration: any,
	postRegistration(params: PostRegistrationType): void,
}
const SignUp: React.FC<Props> = ({ postRegistration, registrationStatus, registration }) => {
	const dispatch = useDispatch();

	const phoneMask: string = '+7 (___) ___-__-__'; // маска телефонного номера
	const signUpInitialState: SignUpState = {
		login: '',
		mail: '',
		firstPassword: '',
		secondPassword: '',
	}
	const invalidValueInitialState: tplotOptions = {
		password: false,
		phone: false,
		mail: false,
		login: false,
	}
	const initialPasswordShowingState = {
		first: false,
		second: false
	}
	const [passwordShowingState, setPasswordShowingState] = useState(() => initialPasswordShowingState)
	const [invalidValueState, setInvalidValueStatus] = useState(() => invalidValueInitialState)
	const [phoneInputValueState, setPhoneInputValueState] = useState(phoneMask);
	const [signUpState, setSignUpState] = useState<SignUpState>(() => signUpInitialState);
	const handleInputChange = (event: any) => {
		event.preventDefault();
	}
	// обработчик телефона
	const handePhoneInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		ChangeNumbers(event, { phoneInputValueState, setPhoneInputValueState });
		if (invalidValueState.phone === true) {
			setInvalidValueStatus({ ...invalidValueState, phone: false });
		}
	};
	const handlePasswordShowingClick = (value: string) => {
		if (value === 'first') {
			setPasswordShowingState({ ...passwordShowingState, first: !passwordShowingState.first })
		} else if (value === 'second') {
			setPasswordShowingState({ ...passwordShowingState, second: !passwordShowingState.second })
		}

	}
	const handleSignUpChange = (name: string, e: React.ChangeEvent<HTMLInputElement>) => {
		setSignUpState({ ...signUpState, [name]: e.target.value })
		if (name === 'mail') {
			setInvalidValueStatus({ ...invalidValueState, mail: false });
		}
		else if (name === 'secondPassword' || name === 'firstPassword') {
			setInvalidValueStatus({ ...invalidValueState, password: false });
		}
		else if (name === 'login') {
			setInvalidValueStatus({ ...invalidValueState, login: false });
		}
		else if (name === 'phone') {
			setInvalidValueStatus({ ...invalidValueState, phone: false });
		}
		if (registrationStatus.error) {
			dispatch({ type: 'HIDE_ERROR' });
		}
	}
	const handleSendClick = () => {
		// проверяем на наличие ошибок в заполнении инпутов
		// Если есть ошибка: вычисляем где именно
		console.log('зашёл в отправку')
		const mailCheckResult = CheckMailValid(signUpState.mail);
		if ((signUpState.firstPassword.length < 8 || signUpState.secondPassword.length < 8 || signUpState.firstPassword !== signUpState.secondPassword) && signUpState.login.length === 0) {
			setInvalidValueStatus({ ...invalidValueState, password: true, login: true });
			return;
		}
		else if (phoneInputValueState.replace(/[^\d]/g, '').length !== 11 && (signUpState.firstPassword.length < 8 || signUpState.secondPassword.length < 8 || signUpState.firstPassword !== signUpState.secondPassword)) {
			setInvalidValueStatus({ ...invalidValueState, phone: true, password: true });
			return
		}
		else if (!mailCheckResult && (signUpState.firstPassword.length < 8 || signUpState.secondPassword.length < 8 || signUpState.firstPassword !== signUpState.secondPassword)) {
			setInvalidValueStatus({ ...invalidValueState, mail: true, password: true });
			return
		}
		else if (signUpState.firstPassword.length < 8 || signUpState.secondPassword.length < 8 || signUpState.firstPassword !== signUpState.secondPassword) {
			setInvalidValueStatus({ ...invalidValueState, password: true});
			return
		}
		else if (signUpState.login.length === 0) {
			setInvalidValueStatus({ ...invalidValueState, login: true});
			return
		}
		else if (phoneInputValueState.replace(/[^\d]/g, '').length !== 11 ) {
			setInvalidValueStatus({ ...invalidValueState, phone: true});
			return
		}
		if (!mailCheckResult) {
			setInvalidValueStatus({ ...invalidValueState, mail: true });
			return
		}
		postRegistration({
			username: signUpState.login,
			password: signUpState.firstPassword,
			email: signUpState.mail,
			phone: phoneInputValueState.replace(/[^\d]/g, '')
		})
	}

	useEffect(() => {
		if (registrationStatus.error) {
			setInvalidValueStatus({ ...invalidValueState, login: registrationStatus.error.hasOwnProperty('username'), password: registrationStatus.error.hasOwnProperty('password'), phone: registrationStatus.error.hasOwnProperty('phone'), mail: registrationStatus.error.hasOwnProperty('mail') });
		}
		if (registration.status === 201) {
			dispatch({ type: 'CLEAR_USER_REG' });
			history.push('/sign_in')
		}
	}, [registrationStatus, registration])
	return (
		<>
			<div className="sign-up-wrap">
				<button className="back-btn" onClick={() => history.goBack()}>
					<img src="/images/navbar/back-icon.svg" alt="назад" />
				</button>
				<div className="sign-up-form">
					{/* <button className="exit-btn" onClick={() => setModalActive(false)}>
					<img src="/images/sign/exit-icon-black-edition.svg" alt="закрыть" />
				</button> */}
					<div className="header-wrap">
						<div className="info-wrap">
							<div className="header-text">
								<p>Зарегистрироваться</p>
							</div>
							<NavLink to={`/sign_in`} className="subheader-text">
								<span className='span_or'>или</span><span className='span_sign-in'>Войти</span>
							</NavLink>
						</div>
						{/* <div className="user">
							<a href="#">Я собственник</a>
						</div> */}
					</div>
					<input id="sign-up-login" type="text" className={`input_sign-up-login ${invalidValueState.login ? 'invalid-value' : ''}`} placeholder="Логин (должен быть уникальным)" value={signUpState.login} onChange={(e) => handleSignUpChange('login', e)} />
					<input
						type="text"
						name="sign-up-phone"
						className={`input_phone ${invalidValueState.phone ? 'invalid-value' : ''}`}
						id="sign-up-phone"
						placeholder="Телефон (желательно, привязанный к WhatsApp)"
						value={phoneInputValueState}
						onKeyDown={(event) => handePhoneInputKeyDown(event)}
						onChange={(e) => handleInputChange(e)}
					/>
					<input type="text" id="sign-up-mail" className={`mail-input ${invalidValueState.mail ? 'invalid-value' : ''}`} placeholder="E-mail (должен быть уникальным)" value={signUpState.mail} onChange={(e) => handleSignUpChange('mail', e)} />
					<div className="password-wrap">
						<div className="password-wrap__first-password password-label-input-wrapper">
							<label htmlFor="sign-up-firstPassword">
								Пароль (минимум 8 символов, строчные и заглавные буквы, спецсимволы)

								<input
									id="sign-up-firstPassword"
									type={!passwordShowingState.first ? 'password' : 'text'}
									className={`password-input ${invalidValueState.password ? 'invalid-value' : ''}`}
									placeholder="Пароль"
									value={signUpState.firstPassword} onChange={(e) => handleSignUpChange('firstPassword', e)}
								/>
								<span className="second-password-show" onClick={() => handlePasswordShowingClick('first')}><img src={`/images/sign/eye-${!passwordShowingState.first ? 'off' : 'on'}.svg`} alt='eye'></img></span>
							</label>
						</div>
						<div className="password-wrap__second-password password-label-input-wrapper">
							<label htmlFor="sign-up-secondPassword">
								Подтверждение пароля (минимум 8 символов, строчные и заглавные буквы, спецсимволы)

								<input
									type={!passwordShowingState.second ? 'password' : 'text'}
									id="sign-up-secondPassword"
									className={`password-input ${invalidValueState.password ? 'invalid-value' : ''}`}
									placeholder="Подтвердите пароль"
									value={signUpState.secondPassword} onChange={(e) => handleSignUpChange('secondPassword', e)}

								/>
								<span className="second-password-show" onClick={() => handlePasswordShowingClick('second')}><img src={`/images/sign/eye-${!passwordShowingState.second ? 'off' : 'on'}.svg`} alt='eye'></img> </span>
							</label>
						</div>
					</div>
					<button className="proceed-btn" onClick={() => handleSendClick()}>Продолжить</button>
					{/* <ul className="links-list">
						<li className="links">
							<a href="#">Войти с помощью Google</a>
						</li>
						<li className="links">
							<a href="#">Войти с помощью Facebook</a>
						</li>
					</ul> */}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		registrationStatus: state.registrationError,
		registration: state.registration
	};
};
const mapDispatchToProps = {
	postRegistration,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
