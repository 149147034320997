import { Dispatch } from "redux";
import axios from "axios";


const domen = "https://vtripe.com";

function getCookie(name) {
	const cookieValue = null;
	if (document.cookie && document.cookie !== "") {
		const cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === name + "=") {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	console.log("cookievalue: ", cookieValue);
	return cookieValue;
}

export const postFirstPage = (first) => {
	return (dispatch) => {
		axios
			.post(domen + "/homes/api/save/hash", first, {
				headers: {
					"X-CSRFToken": getCookie("csrftoken"),
				},
			})
			.then((firstPage) => {
				console.log("я сохранил", firstPage);
				dispatch({
					type: "GET_FIRST_PAGE",
					firstPage,
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getHousesList = (hash) => {
	console.log(hash);
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.get(
				domen + "/homes/api/houseslist",
				{
					params: {
						aid: String(hash),
						parameters: "",
					},
				},
				{
					headers: {
						"X-CSRFToken": getCookie("csrftoken"),
					},
				}
			)
			.then((housesList) => {
				dispatch({
					type: "GET_HOUSES",
					housesList,
				});
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getHousesListWithFilters = (fills) => {
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.get(
				domen + "/homes/api/houseslist",
				{
					params: fills,
				},
				{
					headers: {
						"X-CSRFToken": getCookie("csrftoken"),
					},
				}
			)
			.then((housesList) => {
				dispatch({
					type: "GET_FILTERS",
					housesList,
				});
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const getHousePage = (params) => {
	console.log("Я ВЫВОЖУ В КОНСОЛ ВАЖНУЮ ИНФОРМАЦИЮ");
	return async (dispatch) => {
		dispatch({
			type: "SHOW_LOADER",
		});
		await axios
			.get(
				domen + "/home/api/info",
				{
					params: params,
				},
				{
					headers: {
						"Access-Control-Allow-Origin": "https://localhost:3000",
						"Access-Control-Allow-Methods": "GET",
						"Access-Control-Allow-Headers": "Content-Type, x-requested-with",
					},
				}
			)
			.then((housePage) => {
				console.log(housePage);
				dispatch({
					type: "GET_HOUSEPAGE",
					housePage,
				});
				dispatch({
					type: "HIDE_LOADER",
				});
			})
			.catch((error) => {
				console.log(error);
			});
	};
};

export const postOrder = (params) => {
	console.log(params);
	return (dispatch) => {
		axios
			.post(domen + "/home/api/send/order", params, {
				headers: {
					"X-CSRFToken": getCookie("csrftoken"),
				},
			})
			.then((housePage) => {
				console.log(housePage);
			})
			.catch((error) => {
				console.log(error);
			});
	};
};
