import * as React from "react";
import { useState, useEffect } from "react";
let myMap;
let myCircle = [];

const YandexMapHousesList = ({ coordState, firstCoord, spHomes, spHash }) => {
	// загрузка скриптов
	const loadScript = (src, onLoad) => {
		if (window.screen.width >= 1200) {
			const script = document.createElement("script");
			script.src = src;
			script.async = true;
			document.body.appendChild(script);
			script.onload = onLoad;
		}
	};
	// инициализация самой карты
	const init = () => {
		// Создаем карту.
		if (document.getElementById("map-i")?.childNodes) {
			document.getElementById("map-i")?.childNodes.length == 0 ? (myMap = null) : null;
		}
		if (coordState && firstCoord && spHomes) {
			const center = [Number(firstCoord[0]), Number(firstCoord[1])];
			if (myMap) {
				console.log("Зашёл в myMap");
				myMap.setCenter(center, 9.5);
				if (myCircle) {
					for (var id in myCircle) {
						myMap.geoObjects.remove(myCircle[id]);
					}
				}
			} else {
				console.log("Не зашёл в MyMap");
				myMap = new ymaps.Map(
					"map-i",
					{
						center: center,
						zoom: 9.5,
						behaviors: ["default", "scrollZoom"],
					},
					{
						searchControlProvider: "yandex#search",
					}
				);
				myMap.controls.add("zoomControl");
			}
		}
		// Создаем круг.
		if (coordState && firstCoord && spHomes) {
			for (var id in spHomes) {
				var a = "/home?aid=" + spHash + "&id=" + spHomes[id].id;
				myCircle[id] = new ymaps.Circle(
					[
						// Координаты центра круга.
						coordState[Number([spHomes[id].id])],
						// Радиус круга в метрах.
						1500,
					],
					{
						// Описываем свойства круга.
						// Содержимое балуна.
						balloonContent:
							"<a href=" + a + " target='_blank'>" + String(spHomes[id].name) + "</a>",
						// Содержимое хинта.
						hintContent: String(spHomes[id].name),
					},
					{
						// Задаем опции круга.
						// Включаем возможность перетаскивания круга.
						// draggable: true,
						// Цвет заливки.
						// Последний байт (77) определяет прозрачность.
						// Прозрачность заливки также можно задать используя опцию "fillOpacity".
						fillColor: "#DB709377",
						// Цвет обводки.
						strokeColor: "#990066",
						// Прозрачность обводки.
						strokeOpacity: 0.8,
						// Ширина обводки в пикселях.
						strokeWidth: 2,
					}
				);

				myMap.geoObjects.add(myCircle[id]);
			}
		}
		// Добавляем круг на карту.
	};
	// вызов функции инициализации карты после прогрузки скрипта
	useEffect(() => { 
		console.log("ZAWEL V USEEFFECT V KARTE");
		loadScript(
			"https://api-maps.yandex.ru/2.0/?apikey=d22b8591-7c26-462c-809b-0d4f5f800f17&load=package.full&lang=ru-RU",
			() => {
				window.ymaps.ready(init);
			}
		);
	}, []);
	return <div id="map-i" style={{ width: "100%", height: "100%" }} />;
};

export { YandexMapHousesList };
