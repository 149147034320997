import * as React from "react";
import { useState } from "react";
import classnames from "classnames";

import "./styles/price-calculate.sass";
import Switch from "react-switch";

const PriceCalculate = ({ arrive_date, away_date, counted_days, price, people }) => {
	const [checked, setChecked] = useState(false);
	const handleChange = () => {
		setChecked((check) => !check);
	};
	return (
		<div className="price-calculate-form">
			<header>
				<h2>Расчёт цены:</h2>
			</header>
			<main>
				<div className="form-group-conditions">
					<div className="form-group-conditions__header">
						<h3>Условия</h3>
					</div>
					<div className="form-group-conditions__content">
						<div className="conditions-item arrive">
							<div className="arrive-paragrpaph"><p>Дата заезда</p></div>
							<div className="arrive-date">
								{arrive_date}
							</div>
						</div>
						<div className="conditions-item away">
						<div className="arrive-paragrpaph"><p>Дата выезда</p></div>
							<div className="arrive-date">
								{away_date}
							</div>
						</div>
						<div className="conditions-item arrive">
							<div className="people-paragrpaph"><p>Кол-во человек</p></div>
							<div className="people-count">
								{people}
							</div>
						</div>
					</div>
				</div>
				<div className="form-group-rent">
					<div className="form-group-rent__header">
						<h3>Аренда</h3>
					</div>
					<div className="form-group-rent__content">
						<div className="reserv-day">
							<button className="reserv-day__btn">
								{counted_days}{" "}
								{counted_days == 1 ? "день" : counted_days > 1 && counted_days < 5 ? "дня" : "дней"}
								<img src="/images/house-page/icons/plus.svg" alt="плюс" />
							</button>
						</div>
						<div className="price-for-days">
							<p>= {price} руб</p>
						</div>
					</div>
				</div>
				{/* <div className="form-group-services">
					<div className="form-group-services__header">
						<h3>Услуги</h3>
					</div>
					<div className="form-group-services__content">
						<div className="service-item-wrap">
							<div className="service-item-wrap__name">
								<p>Дом</p>
							</div>

							<div className="service-list">
								<div className="service-list__name">
									<p>Баня</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Аренда квадроцикла</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Аренда мангала</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Завтраки</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Постельное белье</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Завтрак</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Услуги массажиста</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Услуги бармена</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-item-price">
								<p>= 7 000 руб</p>
							</div>  
						</div>
                        <div className="service-item-wrap">
							<div className="service-item-wrap__name">
								<p>Приглашенные специалисты</p>
							</div>

							<div className="service-list">
								<div className="service-list__name">
									<p>Банщик</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Клининг</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Услуги массажиста</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Услуги бармена</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Постельное белье</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Завтрак</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Услуги массажиста</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-list">
								<div className="service-list__name">
									<p>Услуги бармена</p>
								</div>
								<div className="service-list__price">
									<p>2000 руб/сут</p>
								</div>

								<div
									className={classnames("switch-box", {
										active: checked,
										passive: !checked,
									})}
								>
									<Switch
										className="react-switch"
										onChange={() => handleChange()}
										checked={checked}
										handleDiameter={8}
										height={10}
										width={21}
										onColor="#FFFFFF"
										offColor="#FFFFFF"
										borderRadius={10}
										boxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										activeBoxShadow="0px 1px 4px rgba(0, 0, 0, 0.25)"
										onHandleColor="#434242"
										offHandleColor="#BEDB60"
									/>
								</div>
							</div>
							<div className="service-item-price">
								<p>= 7 000 руб</p>
							</div>  
						</div>
					</div> 
				</div>*/}
			</main>
			<footer>
				<div className="result-price">
					<p>= {price} руб</p>
				</div>
			</footer>
		</div>
	);
};

export { PriceCalculate };
