const Ascroll = (scrollParams) => {
	if (document.querySelector("#houses-side")) {
		if (scrollParams.b == null) {
			var Sa = getComputedStyle(scrollParams.a, ""),
				s = "";
			for (var i = 0; i < Sa.length; i++) {
				if (
					Sa[i].indexOf("overflow") == 0 ||
					Sa[i].indexOf("padding") == 0 ||
					Sa[i].indexOf("border") == 0 ||
					Sa[i].indexOf("outline") == 0 ||
					Sa[i].indexOf("box-shadow") == 0 ||
					Sa[i].indexOf("background") == 0
				) {
					s += Sa[i] + ": " + Sa.getPropertyValue(Sa[i]) + "; ";
				}
			}
			scrollParams.b = document.createElement("div");
			scrollParams.b.style.cssText =
				s +
				" box-sizing: border-box; height: calc(100vh); width: " +
				scrollParams.a.offsetWidth +
				"px;	";
			scrollParams.a.insertBefore(scrollParams.b, scrollParams.a.firstChild);
			var l = scrollParams.a.childNodes.length;
			for (var i = 1; i < l; i++) {
				scrollParams.b.appendChild(scrollParams.a.childNodes[1]);
			}
			scrollParams.a.style.height = scrollParams.b.getBoundingClientRect().height + "px";
			scrollParams.a.style.padding = "0";
			scrollParams.a.style.border = "0";
		}
		var Ra = scrollParams.a.getBoundingClientRect(),
			R = Math.round(
				Ra.top +
					scrollParams.b.getBoundingClientRect().height -
					document.querySelector("#houses-side").getBoundingClientRect().bottom
			); // селектор блока, при достижении нижнего края которого нужно открепить прилипающий элемент
		if (Ra.top - scrollParams.P <= 0) {
			if (Ra.top - scrollParams.P <= R) {
				scrollParams.b.className = "stop";
				scrollParams.b.style.top = -R + "px";
			} else {
				scrollParams.b.className = "sticky";
				scrollParams.b.style.top = scrollParams.P + "px";
			}
		} else {
			scrollParams.b.className = "";
			scrollParams.b.style.top = "";
		}
		window.addEventListener(
			"resize",
			function () {
				scrollParams.a.children[0].style.width = getComputedStyle(scrollParams.a, "").width;
			},
			false
		);
	}
};

export { Ascroll };
