const ThirdPageReducer = (housePage = [], action) => {
	switch (action.type) {
		case "GET_HOUSEPAGE":
			return action.housePage.data;
		case "POST_ORDER":
			return action.housePage.data
		default:
			return housePage;
	}
};

export { ThirdPageReducer };