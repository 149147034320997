import * as React from "react";

const Spinner = () => {
    const spinnerStyle = {
        display: 'block',
        margin: 'auto auto'
      };
      
	return <img src="/images/houseslist/gifs/lazy-load-loader.gif" alt="spinner" style={spinnerStyle} />;
};

export { Spinner };
