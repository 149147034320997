import axios from "axios";

const domen = "https://vtripe.com";

type PostRegistrationType = {
    username: string,
    password: string,
    email: string,
    phone: string
}

type PostTokenState = {
    username: string,
    password: string,
}

export const postToken = (params: PostTokenState) => {

    console.log("пост токен")
    return (dispatch: any) => {
        axios
            .post(domen + "/auth/token/jwt/create", params)
            .then((token) => {
                dispatch({
                    type: "POST_TOKEN",
                    token,
                });
            })
            .then(() => {
                console.log('хороший статус при посте токена')
                dispatch({
                    type: "AUTH_SUCCESS",
                });
            })
            .then(() => {
                dispatch({
                    type: "LOGGED_SUCCESS",
                });
            })
            .catch((error) => {
                dispatch({
                    type: "LOGGED_ERROR",
                });
                dispatch({
                    type: "AUTH_FAILED",
                });
                console.log(error);
            });
    };
};

export const postRegistration = (params: PostRegistrationType) => {
    return async (dispatch: any) => {
        dispatch({
            type: "HIDE_ERROR",
        });
        await axios
            .post(domen + "/auth/users/", params)
            .then((registrationInfo) => {
                dispatch({
                    type: "POST_CREATE_USER",
                    registrationInfo,
                });
                console.log(registrationInfo);
            })
            .catch((error: any) => {
                console.log(error.response);
                if (error.response && error.response.data && error.response.data.username || error.response.data.mail || error.response.data.password || error.response.data.phone) {
                    const errorResponse = error.response.data
                    dispatch({
                        type: "SET_ERROR",
                        error: errorResponse,
                    });
                }
                else {
                    console.log(error);
                }
            });
    };
};

export const refreshToken = (params: any) => {
    console.log('Зашёл в рефреш токена')
    return async (dispatch: any) => {
        await axios
            .post(domen + "/auth/token/jwt/refresh", {
                refresh: params,
            })

            .then((token: any) => {
                dispatch({
                    type: "POST_TOKEN",
                    token,
                });
            })
            .then(() => {
                console.log('хороший статус при рефреше')
                dispatch({
                    type: "AUTH_SUCCESS",
                });
            })

            .catch((error) => {
                console.log('плохой')
                console.log(error);
                dispatch({
                    type: "AUTH_FAILED",
                });

            });
    };
};
