import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, useParams, Redirect, HashRouter } from "react-router-dom";
import LazyLoad from "react-lazyload";
import { Footer } from "../universal/mainThreePages/footer/Footer.jsx";
import Navbar from "../universal/mainThreePages/navbar/Navbar.jsx";
import Filters from "./filters/Filters.jsx";
import ReactLoading from "react-loading";
import "./styles/houseslist.sass";
import { HousePage } from "../housepage/HousePage.jsx";
import { getHousesList } from "../../Actions/Actions.js";
import { getAllUrlParams } from "../../scripts/getAllUrlParams.js";
import { isJSDocNameReference } from "typescript";
import { history } from "../../Store/Store.js";
import { HouseWrapItem } from "./HouseWrapItem.jsx"; // блоки домов
import { refreshPage } from "../../scripts/refreshPage.js"; // перезагрузка страницы через 0.5 секунд
import { YandexMapHousesList } from "./YandexMapHousesList.jsx"; // яндекс карта
import { Ascroll } from "./Ascroll.js";
import lazyloadPreloader from "./public/images/houseslist/gifs/lazy-load-loader.gif";
import { Spinner } from "./Spinner.jsx";
const HousesList = ({ housesListState, getHousesList }) => {
	const dispatch = useDispatch();
	const [filterState, setFilterState] = useState();
	// open filter
	const _onFilterButtonClick = () => {
		setFilterState(true);
	};
	const [reservedPositionsState, setReservedPositionsState] = useState(() => null);
	const [spFlag, setSpFlag] = useState(true);
	const [secondPageFlag, setSecondPageFlag] = useState(true);
	const [coordState, setCoordState] = useState();
	const [firstCoord, setFirstCoordState] = useState();
	const [startPrice, setStartPrice] = useState();
	const [finishPrice, setFinishPrice] = useState();
	const [mapBlockState, setMapBlockState] = useState(() => true);
	const [appState, setAppState] = useState({
		town: "", // Казань
		arrive: "", // формат 05.08.2021
		away: "", // формат 05.08.2021
		people: "", // 5
		parameters: "",
		page: 2,
		hash: "",
		pool: false,
		bilard: false,
		sauna: false,
		venic: false,
		river: false,
		besedka: false,
		tennis: false,
		animal: false,
		wifi: false,
		bed: 0,
		bedroom: 0,
		amount: "",
	});

	useEffect(() => {
		console.log(getAllUrlParams().aid);
		getHousesList(getAllUrlParams().aid);
	}, []);
	console.log("СОСТОЯНИЕ ВТОРОГО КОМПОНЕНТА: ", housesListState);
	const loading = useSelector((state) => state.loadingStatus.loading);
	useEffect(() => {
		if (housesListState.length != 0) {
			console.log("ЗАХОЖУ СЮДА!!!!!!!!!!!!");
			setAppState({
				...appState,
				town: housesListState.town,
				people: housesListState.people,
				arrive: housesListState.arrive,
				away: housesListState.away,
				pool: housesListState.pool,
				bilard: housesListState.bilard,
				sauna: housesListState.sauna,
				venic: housesListState.venic,
				river: housesListState.river,
				besedka: housesListState.besedka,
				tennis: housesListState.tennis,
				animal: housesListState.animal,
				wifi: housesListState.wifi,
				amount: "",
				page: 2,
				bed: housesListState.bed,
				bedroom: housesListState.bedroom,
				hash: "",
				parameters: "",
			});
			setStartPrice(housesListState.start_price);
			setFinishPrice(housesListState.finish_price);
		}

	}, [housesListState]);
	console.log("зарезервированные айди: ", reservedPositionsState);
	const changeStartPrice = (event) => {
		setStartPrice(event.currentTarget.value);
	};

	const changeFinishPrice = (event) => {
		setFinishPrice(event.currentTarget.value);
	};

	useEffect(() => {
		const yandex_map = {};
		let flag = true;
		if (housesListState.length != 0) {
			for (let index = 0; index < housesListState.homes.length; index++) {
				const id = housesListState.homes[index].id;
				const cord = housesListState.homes[index].yandex_map;
				const items = cord.split(" ");
				if (items[0] != "undefined" && items[1] != "undefined") {
					yandex_map[id] = [Number(items[0]), Number(items[1])];
				}
				if (flag) {
					setFirstCoordState(items);
					flag = false;
				}
				index++;
			}
			setCoordState(yandex_map);
		}
	}, [housesListState.homes]);

	// // ГОВНОКОД
	// for adequate scrolling
	useEffect(() => {
		if (window.screen.width >= 1200 && !loading) {
			setMapBlockState(false);
			const scrollParams = {
				a: document.querySelector("#map"),
				b: null,
				P: 0,
			};
			window.addEventListener("scroll", () => Ascroll(scrollParams), true);
			document.body.addEventListener("scroll", () => scroll(scrollParams), true);
		}
	}, [loading]);
	return loading ? (
		<>
			<div className="main-preloader">
				<img src="/images/preloader.gif" alt="preloader" />
			</div>
		</>
	) : (
		<>
			<div className="houseslist-navbar" id="navbar">
				<Navbar
					navbarAppState={appState}
					setNavbarAppState={setAppState}
					startPrice={startPrice}
					finishPrice={finishPrice}
				/>
			</div>
			<div className="main houseslist">
				<div className="container">
					<div className="houses-list">
						{housesListState.length != 0 ? (
							<div className="header-text">
								<h1>Жилье рядом</h1>
								{housesListState.length != 0 ? (
									<p className="homes-count">Найдено домов: {housesListState.homes.length}</p>
								) : null}
							</div>
						) : null}

						<div className="subheader">
							<div className="subheader__filters">
								<button
									className="subheader__filters__btn"
									type="button"
									value="фильтры"
									onClick={() => _onFilterButtonClick()}
								>
									фильтры
								</button>
								<div className="modal-filter">
									{filterState && appState ? (
										<Filters
											active={filterState}
											setActive={setFilterState}
											appState={appState}
											setAppState={setAppState}
											startPrice={startPrice}
											setStartPrice={setStartPrice}
											finishPrice={finishPrice}
											setFinishPrice={setFinishPrice}
										/>
									) : null}
								</div>
							</div>
							{housesListState.length != 0 ? (
								<div className="subheader__cost">
									<p>цена</p>
									<div className="subheader__cost__interval from">
										<label htmlFor="cost-from">от</label>
										{startPrice != 0 ? (
											<input type="number" value={startPrice} onChange={changeStartPrice} />
										) : (
											<input type="number" value={startPrice} onChange={changeStartPrice} />
										)}
									</div>
									<div className="subheader__cost__interval to">
										<label htmlFor="cost-to">до</label>
										{finishPrice != 0 ? (
											<input type="number" value={finishPrice} onChange={changeFinishPrice} />
										) : (
											<input type="number" value={finishPrice} onChange={changeFinishPrice} />
										)}
									</div>
								</div>
							) : null}
						</div>
						{housesListState.length != 0 ? (
							<div className="houses" id="houses-side">
								{
									(console.log(housesListState.homes),
									housesListState.homes
										? housesListState.homes.map((house) => (
												<LazyLoad placeholder={<Spinner />} key={house.id}>
													<HouseWrapItem house={house} sp={housesListState} />
												</LazyLoad>
										  ))
										: null)
								}
							</div>
						) : null}
						<div className="subhouses-block">
							<div className="subhouses-block__header">
								<p>Не нашли что искали?</p>
							</div>
							<div className="subhouses-block__subheader">
								<p>
									Напишите нам на электронную почту. <br />
									Наш менеджер свяжется с Вами
								</p>
							</div>
							<div className="subhouses-block__btn">
								<button className="write-btn">Написать</button>
							</div>
						</div>
					</div>
					<div className="map" id="map">
						{window.screen.width >= 1200 &&
						coordState &&
						housesListState.homes &&
						firstCoord &&
						housesListState.hash ? (
							<YandexMapHousesList
								coordState={coordState}
								spHomes={housesListState.homes}
								firstCoord={firstCoord}
								spHash={housesListState.hash}
							/>
						) : null}
					</div>
				</div>
			</div>
			<div className="houseslist-footer">
				<Footer />
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		housesListState: state.secondPage,
	};
};

const mapDispatchToProps = {
	getHousesList,
};

export default connect(mapStateToProps, mapDispatchToProps)(HousesList);
