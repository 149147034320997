interface IActualMonth {
    monthIndex: number,
    Year: number,
    Dates: number[]
}

interface PriceState {
    [id: number]: {
        price: number,
        day: number,
    }
}

type SelectedDate = {
    arrive: string,
    away: string,
}

// next month button click
export const handleNextMonthClick = (actualMonthState: IActualMonth, setActualMonthState: any, setSpecPriceState: React.Dispatch<React.SetStateAction<PriceState>>): void => {
    setSpecPriceState({});
    const nextMonthDate: Date = new Date(actualMonthState.Year, actualMonthState.monthIndex)
    if (nextMonthDate.getMonth() === 11) {
        setActualMonthState({
            monthIndex: 0,
            Year: nextMonthDate.getFullYear() + 1,
            Dates: Array.apply(null, { length: new Date(nextMonthDate.getFullYear() + 1, 1, 0).getDate() + 1 - nextMonthDate.getDate() }).map(function (_: any, idx: any) {
                return idx + nextMonthDate.getDate();
            })
        })
    } else {
        setActualMonthState({
            ...actualMonthState,
            monthIndex: nextMonthDate.getMonth() + 1,
            Dates: Array.apply(null, { length: new Date(nextMonthDate.getFullYear(), nextMonthDate.getMonth() + 2, 0).getDate() + 1 - nextMonthDate.getDate() }).map(function (_: any, idx: any) {
                return idx + nextMonthDate.getDate();
            })
        })
    }

}

// prew month button click
export const handlePrewMonthClick = (actualMonthState: IActualMonth, setActualMonthState: any, setSpecPriceState: React.Dispatch<React.SetStateAction<PriceState>>): void => {
    setSpecPriceState({});
    const prewMonthDate: Date = new Date(actualMonthState.Year, actualMonthState.monthIndex)
    if (prewMonthDate.getMonth() === 0) {
        setActualMonthState({
            monthIndex: 11,
            Year: prewMonthDate.getFullYear() - 1,
            Dates: Array.apply(null, { length: new Date(prewMonthDate.getFullYear() - 1, 12, 0).getDate() + 1 - prewMonthDate.getDate() }).map(function (_: any, idx: any) {
                return idx + prewMonthDate.getDate();
            })
        })
    }
    else {
        setActualMonthState({
            ...actualMonthState,
            monthIndex: prewMonthDate.getMonth() - 1,
            Dates: Array.apply(null, { length: new Date(prewMonthDate.getFullYear(), prewMonthDate.getMonth(), 0).getDate() + 1 - prewMonthDate.getDate() }).map(function (_: any, idx: any) {
                return idx + prewMonthDate.getDate();
            })
        })
    }
}

// get month name by year and month index
export const getMonthName = (actualYear: number, actualMonthIndex: number): string => {
    const Month = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентярь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];
    return Month[new Date(actualYear, actualMonthIndex).getMonth()]
}

// get now date
export const getActualMonth = (selectedDateState: SelectedDate): IActualMonth => {
    const ActualDate: Date = new Date()

    if (selectedDateState.arrive.length > 0 && selectedDateState.away.length > 0) {
        const startDay = Number(selectedDateState.arrive.split('-')[2]);
        const startMonth = Number(selectedDateState.arrive.split('-')[1]);
        // const startYear = Number(selectedDateState.arrive.split('-')[0]);
        const endDay = Number(selectedDateState.away.split('-')[2]);
        const endMonth = Number(selectedDateState.away.split('-')[1]);
        // const endYear = Number(selectedDateState.away.split('-')[0]);
        const startDate = new Date(selectedDateState.arrive);
        // const endDate = new Date(selectedDateState.away);
        if (startMonth != endMonth) {
            let firstArray = Array.apply(null, { length: new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0).getDate() + 1 - startDate.getDate() }).map(function (_: any, idx: any) {
                return idx + startDate.getDate();
            })
            let secondArray = Array.apply(null, { length: endDay }).map(function (_: any, idx: any) {
                return idx + 1;
            })

            return {
                monthIndex: ActualDate.getMonth(),
                Year: ActualDate.getFullYear(),
                Dates: firstArray.concat(secondArray),
            };
        }
        else if (startMonth == endMonth) {

            return {
                monthIndex: ActualDate.getMonth(),
                Year: ActualDate.getFullYear(),
                Dates: Array.apply(null, { length: endDay - startDay + 1 }).map(function (_: any, idx: any) {
                    return idx + startDay;
                })
            };
        }
    }
    else {
        return {
            monthIndex: ActualDate.getMonth(),
            Year: ActualDate.getFullYear(),
            Dates: Array.apply(null, { length: new Date(ActualDate.getFullYear(), ActualDate.getMonth() + 1, 0).getDate() + 1 - ActualDate.getDate() }).map(function (_: any, idx: any) {
                return idx + ActualDate.getDate();
            })
        };
    }
}