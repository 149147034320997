import * as React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Footer } from "../universal/mainThreePages/footer/Footer.jsx";
import Navbar from "../universal/mainThreePages/navbar/Navbar.jsx";
import Calendar from "./datepicker/Calendar.jsx";
import { PriceCalculate } from "./price-calculate/PriceCalculate.jsx";
import "./styles/housepage.sass";
import classNames from "classnames";
import { getUrlParamsWithId } from "../../scripts/getUrlParamsWithId.js";
import { getHousePage, postOrder } from "../../Actions/Actions.js";
import "../../scripts/phoneinput.js";
import { getAllUrlParams } from "../../scripts/getAllUrlParams.js";
import Slider from "react-slick";
import { Ascroll } from "../houseslist/Ascroll.js";
import { YandexMapHousePage } from "./YandexMapHousePage.jsx";
import { ChangeNumbers } from "../../scripts/phone-number-filling.js";
import { ModalContext } from "../modal/modalContext/ModalContext";
import PhotosModalContent from "./photosModalContent/PhotosModalContent";
import useWindowDimensions from "../customHooks/useWindowDimesions";

let myMap;
const myCircle = {};

const HousePage = ({ housePageState, getHousePage }) => {
	const { handleModal } = useContext(ModalContext);
	const { width } = useWindowDimensions();
	const phoneMask = "+7(___)___-__-__";
	const [phoneInputValueState, setPhoneInputValueState] = useState("+7(___)___-__-__");
	const dispatch = useDispatch();
	const [imageMapState, setimageMapState] = useState(() => true);
	const [stickyState, setStickyState] = useState(false);
	const [coordState, setCoordState] = useState();
	const [imageState, setImageState] = useState(() => 0);
	const [orderStatus, setOrderStatus] = useState(false);
	const [phoneStatus, setPhoneStatus] = useState(false);
	const [houeMapState, setHouseMapState] = useState();
	const settings = {
		lazyload: true,
		dots: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};

	const sliderRef = useRef(null);
	useEffect(() => {
		getHousePage({
			aid: getAllUrlParams().aid,
			id: getAllUrlParams().id,
			m: 0,
		});
	}, []);
	const loadingHousePage = useSelector((state) => state.loadingStatus.loading);
	useEffect(() => {
		if (housePageState.house) {
			setCoordState(housePageState.house.yandex_map.split(" "));
		}
	}, [housePageState.house]);

	const handeInputKeyDown = (event) => {
		console.log(event);
		ChangeNumbers(event, { phoneInputValueState, setPhoneInputValueState });
	};

	const handleInputChange = (event) => {
		console.log(event);
		event.preventDefault();
	};

	const booking = () => {
		if (phoneInputValueState.replace(/[^\d]/g, "").length !== 11) {
			setPhoneStatus(true);
			return;
		}
		if (!housePageState.price) {
			return;
		}
		setPhoneStatus(false);
		dispatch(
			postOrder({
				aid: getAllUrlParams().aid,
				house_id: getAllUrlParams().id,
				phone: phone,
				price: housePageState.price,
			})
		);
		setOrderStatus(true);
	};

	return loadingHousePage ? (
		<>
			<div className="main-preloader">
				<img src="/images/preloader.gif" alt="preloader" />
			</div>
		</>
	) : (
		<>
			<div className="housepage-wrap">
				<div className="house-page-navbar">
					<Navbar toSecondPage={getAllUrlParams().aid} />
				</div>
				<main>
					<div className="container">
						<ul className="main-titles">
							<li className="lodging main-titles__spec">Жильё</li>
							<li className="entertainment main-titles__spec">Развлечения</li>
							<li className="community main-titles__spec">Сообщество</li>
						</ul>

						<div className="main house-page">
							{housePageState.length != 0 ? (
								<>
									<div className="house-main first-column">
										<div className="house-main__name">{housePageState.house.name}</div>

										<div className="house-main__image">
											{width <= 768 ? (
												<div className="mob-img">
													<div className="slide-image-wrap">
														<button
															className="prewClick"
															onClick={() => sliderRef.current.slickPrev()}
														>
															<img src="images/houseslist/icons/prew-icon.svg" alt="назад" />
														</button>
														<button
															className="nextClick"
															onClick={() => sliderRef.current.slickNext()}
														>
															<img src="images/houseslist/icons/next-icon.svg" alt="дальше" />
														</button>
														<Slider {...settings} ref={sliderRef}>
															{housePageState.photos.map(function (slide) {
																return (
																	<img
																		src={slide}
																		alt="дом"
																		onClick={() =>
																			handleModal(
																				<PhotosModalContent housePhotos={housePageState.photos} />
																			)
																		}
																	/>
																);
															})}
														</Slider>
													</div>
												</div>
											) : (
												<>
													<div className="slide-image-wrap">
														<button
															className="prewClick"
															onClick={() => sliderRef.current.slickPrev()}
														>
															<img src="images/houseslist/icons/prew-icon.svg" alt="назад" />
														</button>
														<button
															className="nextClick"
															onClick={() => sliderRef.current.slickNext()}
														>
															<img src="images/houseslist/icons/next-icon.svg" alt="дальше" />
														</button>
														<Slider {...settings} ref={sliderRef}>
															{housePageState.photos.map(function (slide) {
																return (
																	<img
																		src={slide}
																		alt="дом"
																		onClick={() =>
																			handleModal(
																				<PhotosModalContent housePhotos={housePageState.photos} />
																			)
																		}
																	/>
																);
															})}
														</Slider>
													</div>
												</>
											)}
										</div>
										{coordState && housePageState.length != 0 && width > 768 ? (
											<div className="house-main__map">
												<YandexMapHousePage
													coordState={coordState}
													housePageState={housePageState}
												/>
											</div>
										) : null}
										{(width > 768) & (width <= 1200) ? (
											<div className="house-description__date">
												<div className="house-description__date__header">
													<p>Даты</p>
												</div>
												<div className="house-description__date__content">
													{housePageState.arrive && housePageState.away ? (
														<Calendar
															arrive={housePageState.arrive}
															away={housePageState.away}
															privatedDate={housePageState.bookings_date}
														/>
													) : null}
												</div>
											</div>
										) : null}
									</div>
									<div className="house-main second-column">
										<div className="house-description">
											<div className="house-description__descr">
												<div className="house-description__descr__header">
													<p>Описание</p>
												</div>
												<div className="house-description__descr__content">
													<p>{housePageState.house.description}</p>
												</div>
											</div>
											<div className="house-description__condition">
												<div className="house-description__condition__header">
													<p>Параметры</p>
												</div>
												<div className="house-description__condition__content">
													<div className="short-info">
														<p>Вместимость до {housePageState.house.count} человек</p>
														<p>
															{housePageState.house.bedroom} спальных комнат, {" "}
															{housePageState.house.bed} спальных мест{" "}
															<img src="/images/house-page/icons/bed.svg" alt="кровать" />
														</p>
													</div>
													<div className="additional-info">
														<div className="additional-info__wrap">
															<div className="info-icon">
																<img src="/images/house-page/icons/stairs.svg" alt="лестница" />
															</div>
															<p>
																{housePageState.house.floors}{" "}
																{housePageState.house.floors == 1
																	? "этаж"
																	: housePageState.house.floors > 1 &&
																	  housePageState.house.floors < 5
																	? "этажа"
																	: "этажей"}
															</p>
														</div>
														{housePageState.house.parameters_model
															? housePageState.house.parameters_model.map((params) => (
																	<div key={params.id} className="additional-info__wrap">
																		<p>{params.rus_version}</p>
																	</div>
															  ))
															: null}
													</div>
												</div>
											</div>
											{housePageState.house.animal ? (
												<div className="house-description__special-conditions">
													<div className="house-description__special-conditions__header">
														<p>Особые условия</p>
													</div>
													<div className="house-description__special-conditions__content">
														<div className="special-conditions-info">
															<div className="special-conditions-info__wrap">
																<div className="info-icon">
																	<img src="/images/house-page/icons/pet.svg" alt="лестница" />
																</div>
																<p>можно с животными</p>
															</div>
														</div>
													</div>
												</div>
											) : null}
											<div className="house-description__date">
												<div className="house-description__date__header">
													<p>Даты</p>
												</div>
												<div className="house-description__date__content">
													{housePageState.arrive && housePageState.away ? (
														<Calendar
															arrive={housePageState.arrive}
															away={housePageState.away}
															privatedDate={housePageState.bookings_date}
														/>
													) : null}
													{/* <div className="prompt">
														<p>*Чтобы посмотреть цену наведите мышкой на дату</p>
													</div> */}
												</div>
											</div>
											{width > 768 && width <= 1200 ? (
												<div className="mob-price-calc">
													<PriceCalculate
														arrive_date={housePageState.arrive}
														away_date={housePageState.away}
														counted_days={housePageState.days_count}
														price={housePageState.price}
														people={housePageState.people}
													/>

													<div className="phone-input">
														<input
															// required
															type="text"
															name="phone"
															placeholder="Введите телефон"
															// maxLength="18"
															value={phoneInputValueState}
															onKeyDown={(event) => handeInputKeyDown(event)}
															onChange={(e) => handleInputChange(e)}
														/>
													</div>
													{phoneStatus ? (
														<div className="phoneError">
															<p>Введите ваш номер телефона</p>{" "}
														</div>
													) : null}
													<div className="reservation">
														<button className="reservation__btn" onClick={() => booking()}>
															Забронировать
														</button>
													</div>
													{orderStatus ? (
														<div className="after-success-order">Ваша заявка отправлена!</div>
													) : null}
													<div className="manager-contact-wrapper">
														<span>Cвязаться самому</span>
														<a href={`tel:${housePageState?.manager}`}>{housePageState?.manager}</a>
													</div>
													<div className="not-blame">
														<p>
															Сайт носит сугубо информационный характер и не является публичной
															офертой, определяемой Статьей 437 (2) ГК РФ
														</p>
													</div>
												</div>
											) : null}
										</div>
									</div>
								</>
							) : null}
							<div className="house-main third-column">
								<div
									className="mob-map"
									style={{
										width: "100%",
										height: "370px",
										marginTop: "20px",
										marginBottom: "20px",
									}}
								>
									{coordState && housePageState.length != 0 && width <= 768 ? (
										<YandexMapHousePage coordState={coordState} housePageState={housePageState} />
									) : null}
								</div>
								<div className="third-column__second_part">
									{housePageState.house ? (
										<PriceCalculate
											arrive_date={housePageState.arrive}
											away_date={housePageState.away}
											counted_days={housePageState.days_count}
											price={housePageState.price}
											people={housePageState.people}
										/>
									) : null}
									<div className="phone-input">
										<input
											// required
											type="text"
											name="phone"
											placeholder="Введите телефон"
											// maxLength="18"
											value={phoneInputValueState}
											onKeyDown={(event) => handeInputKeyDown(event)}
											onChange={(e) => handleInputChange(e)}
										/>
									</div>
									{phoneStatus ? (
										<div className="phoneError">
											<p>Введите ваш номер телефона</p>{" "}
										</div>
									) : null}

									<div className="reservation">
										<button className="reservation__btn" onClick={() => booking()}>
											Забронировать
										</button>
									</div>
									{orderStatus ? (
										<div className="after-success-order">Ваша заявка отправлена!</div>
									) : null}
									<div className="manager-contact-wrapper">
										<span>Cвязаться самому</span>
										<a href={`tel:${housePageState?.manager}`}>{housePageState?.manager}</a>
									</div>
									<div className="not-blame">
										<p>
											Сайт носит сугубо информационный характер и не является публичной офертой,
											определяемой Статьей 437 (2) ГК РФ
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</main>
				<Footer />
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		housePageState: state.thirdPage,
	};
};

const mapDispatchToProps = {
	getHousePage,
};

export default connect(mapStateToProps, mapDispatchToProps)(HousePage);
