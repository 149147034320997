const ManageCalendarReducer = (manageCalendar = [], action) => {
	switch (action.type) {
		case "GET_SYNONYM_MANAGE_CALENDAR":
			return action.manageCalendar.data;
		case "GET_ALL_HOUSES_MANAGE_CALENDAR":
			return action.manageCalendar.data;
		case "GET_WITH_FILTERS_MANAGE_CALENDAR":
			return action.manageCalendar.data;
		case "DELETE_HOUSES":
			return [];
		default:
			return manageCalendar;
	}
};

const ManageFiltersCalendarReducer = (manageFiltersCalendar = [], action) => {
	switch (action.type) {
		case "GET_WITH_FILTERS_MANAGE_CALENDAR123":
			console.log(action.manageFiltersCalendar);
			return action.manageFiltersCalendar.data;
		default:
			return manageFiltersCalendar;
	}
};

const ManageCalendarReservedDatesReducer = (manageCalendarReservedDates = [], action) => {
	switch (action.type) {
		case "GET_RESERVED_DATES_MANAGE_CALENDAR":
			return action.manageCalendarReservedDates.data;
		default:
			return manageCalendarReservedDates;
	}
};

const ManageCalendarBookingsrReducer = (manageCalendarBookings = [], action) => {
	switch (action.type) {
		case "POST_CREATE_BOOKINGS":
			return action.manageCalendarBookings.data;
		default:
			return manageCalendarBookings;
	}
};

const ManageCalendarDiscountReducer = (manageCalendarDiscount = [], action) => {
	switch (action.type) {
		case "GET_DISCOUNT_MANAGE_CALENDAR":
			return action.manageCalendarDiscount.data.discounts;
		default:
			return manageCalendarDiscount;
	}
};

const ManageCalendarSpecPriceReducer = (manageCalendarSpecPrice = [], action) => {
	switch (action.type) {
		case "GET_SPECPRICE_MANAGE_CALENDAR":
			return action.manageCalendarSpecPrice.data;
		default:
			return manageCalendarSpecPrice;
	}
};

const ManageCalendarPostSpecPriceReducer = (manageCalendarPostPrice = [], action) => {
	switch (action.type) {
		case "POST_SPEC_PRICE":
			return action.manageCalendarPostPrice.data;
		default:
			return manageCalendarPostPrice;
	}
};

const LogoutReducer = (logout = [], action) => {
	switch (action.type) {
		case "POST_LOGOUT":
			return action.logout.data;
		default:
			return logout;
	}
};

export {
	ManageCalendarReducer,
	ManageFiltersCalendarReducer,
	ManageCalendarReservedDatesReducer,
	ManageCalendarBookingsrReducer,
	ManageCalendarDiscountReducer,
	ManageCalendarSpecPriceReducer,
	ManageCalendarPostSpecPriceReducer,
	LogoutReducer,
};
