const defaultToken = {
	access: !!localStorage.getItem("access") ? localStorage.getItem("access") : '',
};

const initState = {
	error: null,
};

const initRegisterState = {
	logged: !!defaultToken.access && defaultToken.access?.length > 0 ? true : null,
};

const initLoggedStatus = {
	error: false,
};

const loggedErrorReducer = (state = initLoggedStatus, action) => {
	switch (action.type) {
		case "LOGGED_ERROR":
			return {
				error: true,
			};
		case "LOGGED_SUCCESS":
			return {
				error: false,
			};
		default:
			return state;
	}
};

const LoggedStatusReducer = (state = initRegisterState, action) => {
	switch (action.type) {
		case "AUTH_FAILED":
			return {
				logged: false,
			};
		case "AUTH_SUCCESS":
			return {
				logged: true,
			};
		default:
			return state;
	}
};

const RegistrationReducer = (registrationInfo = [], action) => {
	switch (action.type) {
		case "POST_CREATE_USER":
			return action.registrationInfo;
		case "CLEAR_USER_REG":
			return (action.registrationInfo = []);
		default:
			return registrationInfo;
	}
};

const ErrorRegistrationReducer = (state = initState, action) => {
	const { error } = action;
	switch (action.type) {
		case "SET_ERROR":
			return {
				error: error,
			};
		case "SET_ERROR":
			return {
				error: null,
			};
		default:
			return state;
	}
};

const TokenReducer = (token = defaultToken, action) => {
	switch (action.type) {
		case "POST_TOKEN":
			localStorage.setItem("access", action.token.data?.access);
			return action.token.data;
		case "LOGOUT_TOKEN":
			return {
				access: '',
				refresh: '',
			}
		default:
			return token;
	}
};


export {
	RegistrationReducer,
	TokenReducer,
	ErrorRegistrationReducer,
	LoggedStatusReducer,
	loggedErrorReducer,
};
