import * as React from "react";

import classnames from "classnames";

import * as calScr from "./CalScr.js";
import "./styles/datepicker.sass";
export default class Calendar extends React.Component {
	static defaultProps = {
		date: new Date(),
		years: [2021, 2022],
		monthNames: [
			"Январь",
			"Февраль",
			"Март",
			"Апрель",
			"Май",
			"Июнь",
			"Июль",
			"Август",
			"Сентябрь",
			"Октябрь",
			"Ноябрь",
			"Декабрь",
		],
		weekDayNames: ["пн", "вт", "ср", "чт", "пт", "сб", "вс"],
		onChange: Function.prototype,
	};
	state = {
		date: new Date(this.props.arrive.split('.')[2], this.props.arrive.split('.')[1] - 1, this.props.arrive.split('.')[0]),
		currentDate: new Date(),
		selectedDate: null,
	};

	get year() {
		return this.state.date.getFullYear();
	}

	get month() {
		return this.state.date.getMonth();
	}

	get day() {
		return this.state.date.getDate();
	}

	handlePrevMonthButtonClick = () => {
		const date = new Date(this.year, this.month - 1);

		this.setState({ date });
	};

	handleNextMonthButtonClick = () => {
		const date = new Date(this.year, this.month + 1);

		this.setState({ date });
	};

	handleDayClick = (date) => {
		this.setState({ selectedDate: date });

		this.props.onChange(date);
	};

	render() {
		const { years, monthNames, weekDayNames } = this.props;
		const { currentDate, selectedDate } = this.state;
		const monthData = calScr.getMonthData(this.year, this.month);
		const arriveDate = new Date(this.props.arrive.split('.')[2], this.props.arrive.split('.')[1] - 1, this.props.arrive.split('.')[0]); 
		const awayDate = new Date(this.props.away.split('.')[2], this.props.away.split('.')[1] - 1, this.props.away.split('.')[0]);
		
		return (
			<>
				<div className="date-picker-form">
					<div className="year-info left">
						<p>{this.year}</p>
					</div>
					<header>
						<input
							type="button"
							value={monthNames[this.month - 1]}
							onClick={this.handlePrevMonthButtonClick}
						/>
						<input type="button" value={monthNames[this.month]} />
						<input
							type="button"
							value={monthNames[this.month + 1]}
							onClick={this.handleNextMonthButtonClick}
						/>
					</header>
					<div className="calendar">
						<table>
							<thead>
								<tr>
									{weekDayNames.map((name) => (
										<th key={name}>{name}</th>
									))}
								</tr>
							</thead>

							<tbody>
								{monthData.map((week, index) => (
									<tr key={index} className="week">
										{week.map((date, index) =>
											date ? 
											(
												(date <= awayDate && date >= arriveDate) ?  (<td
													key={index}
													className={classnames("day counted", {
														today: calScr.areEqual(date, currentDate),
														selected: calScr.areEqual(date, selectedDate),
													})}
													onClick={() => this.handleDayClick(date)}
												>
													{date.getDate()}
												</td> )
												:
												 (
													((this.props.privatedDate).includes(new Date(date.getTime() + 86400000).toISOString().substring(0, 10))) ? 
													(<td
													key={index}
													className={classnames("day privated", {
														today: calScr.areEqual(date, currentDate),
														selected: calScr.areEqual(date, selectedDate),
													})}
													onClick={() => this.handleDayClick(date)}
												>
													{date.getDate()}
												</td> ) :
												 (<td
													key={index}
													className={classnames("day", {
														today: calScr.areEqual(date, currentDate),
														selected: calScr.areEqual(date, selectedDate),
													})}
													onClick={() => this.handleDayClick(date)}
												>
													{date.getDate()}
												</td> )
											) 
										) : (
												<td key={index} />
											))}
									</tr>
								))}
							</tbody>
						</table>
						{window.screen.width > 425 ? (
							<div className="additional-wrap">
								<div className="second-content">
									{/* <div className="low-price">
										<h2>Самые низкие цены:</h2>
										<ul>
											<li>6</li>
											<li>13</li>
											<li>20</li>
										</ul>
									</div> */}
									<div className="designations-wrap">
										<div className="designations-wrap__item">
											<div className="designation booked  "></div>
											<p>Занято</p>
										</div>
										{/* <div className="designations-wrap__item">
											<div className="designation free"></div>
											<p>Свободно</p>
										</div>  */}
										<div className="designations-wrap__item">
											<div className="designation your"></div>
											<p>Выбранные даты</p>
										</div>
										{/* <div className="designations-wrap__item">
											<div className="designation impossible"></div>
											<p>Бронировние не возможно по техническим причинам</p>
										</div> */}
									</div>
								</div>
								{/* <div className="change-date">
									<button className="change-date__btn">Изменить даты</button>
								</div> */}
							</div>
						) : null}
					</div>
					<div className="year-info right">
						<p>{this.year}</p>
					</div>
				</div>
				{window.screen.width <= 425 ? (
					<div className="additional-wrap-mob">
						<div className="second-content">
							<div className="designations-wrap">
								<div className="designations-wrap__item">
									<div className="designation booked  "></div>
									<p>Занято</p>
								</div>
								{/* <div className="designations-wrap__item">
									<div className="designation free"></div>
									<p>Свободно</p>
								</div> */}
								<div className="designations-wrap__item">
									<div className="designation your"></div>
									<p>Выбранные даты</p>
								</div>
								{/* <div className="designations-wrap__item">
									<div className="designation impossible"></div>
									<p>Бронировние не возможно по техническим причинам</p>
								</div> */}
							</div>
						</div>
						{/* <div className="change-date">
							<button className="change-date__btn">Изменить даты</button>
						</div> */}
					</div>
				) : null}
			</>
		);
	}
}
