import * as React from "react";
import { DatePickerForm } from "./../datepicker/DatePicker.jsx";

import "./styles/footer.sass";
const Footer = () => {
	return (
		<>
			<div className="footer">
				<div className="container">
					<div className="footer-links-wrap">
						{/* <div className="footer-links-column first">
							<ul className="footer-links-list">
								<li className="footer-link">
									<a href="#">О нас</a>
								</li>
								<li className="footer-link">
									<a href="#">Правила площадки</a>
								</li>
								<li className="footer-link">
									<a href="#">Безопасность и конфиденциальность</a>
								</li>
							</ul>
						</div>
						<div className="footer-links-column second">
							<ul className="footer-links-list">
								<li className="footer-link">
									<a href="#">Собственникам</a>
								</li>
								<li className="footer-link">
									<a href="#">Партнерам</a>
								</li>
								<li className="footer-link">
									<a href="#">Пользователям</a>
								</li>
							</ul>
						</div> */}
						<div className="footer-links-column third">
							<ul className="footer-links-list">
								{/* <li className="footer-link">
									<a href="#">Помощь</a>
								</li>
								<li className="footer-link">
									{" "}
									<br />{" "}
								</li> */}
								<li className="footer-link">
         <a href="https://fasie.ru/programs/programma-studstartup/">Проект создан при поддержке Федерального государственного бюджетного учреждения "Фонд содействия развитию малых форм предприятий в научно-технической сфере в рамках программы "Студенческий стартап" федерального проекта "Платформа университетского технологического предпринимательства"</a>
         <br></br><img src="https://fasie.ru/local/templates/.default/markup/img/logo_w.svg" alt="альтернативный текст"></img>
        </li>
							</ul>
						</div>
					</div>
					{/* <ul className="footer-icons-wrap">
						<li className="footers-icons facebook-icon">
							<a href="#">
								<img src="/images/mainpage/icon/footer/facebook.svg" alt="facebook icon" />
							</a>
						</li>
						<li className="footers-icons twitter-icon">
							<a href="#">
								<img src="/images/mainpage/icon/footer/twitter.svg" alt="twitter icon" />
							</a>
						</li>
						<li className="footers-icons vk-icon">
							<a href="#">
								<img src="/images/mainpage/icon/footer/vk.svg" alt="vk icon" />
							</a>
						</li>
						<li className="footers-icons instagram-icon">
							<a href="#">
								<img src="/images/mainpage/icon/footer/instagram.svg" alt="instagram icon" />
							</a>
						</li>
						<li className="footers-icons tiktok-icon">
							<a href="#">
								<img src="/images/mainpage/icon/footer/tik-tok.svg" alt="tik-tok icon" />
							</a>
						</li>
					</ul> */}
				</div>
			</div>
		</>
	);
};

export { Footer };
