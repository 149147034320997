import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'
import { History } from 'history';
import { FirstPageReducer } from './FirstPageReducer.js';
import { SecondPageReducer } from './SecondPageReducer.js';
import { ThirdPageReducer } from './ThirdPapeReducer.js';
import { AuthLoadingReducer, LoadingReducer } from './LoadingReducer.js';
import { LogoutReducer, ManageCalendarBookingsrReducer, ManageCalendarDiscountReducer, ManageCalendarPostSpecPriceReducer, ManageCalendarReducer, ManageCalendarReservedDatesReducer, ManageCalendarSpecPriceReducer, ManageFiltersCalendarReducer } from './ManageCalendarReducer.js';
import { ErrorRegistrationReducer, loggedErrorReducer, LoggedStatusReducer, RegistrationReducer, TokenReducer } from './RegistrationReducer.js';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    firstPage: FirstPageReducer,
    secondPage: SecondPageReducer,
    thirdPage: ThirdPageReducer,
    loadingStatus: LoadingReducer,
    manageCalendar: ManageCalendarReducer,
    manageCalendarReservedDates: ManageCalendarReservedDatesReducer,
    manageCalendarBookings: ManageCalendarBookingsrReducer,
    manageCalendarDiscount: ManageCalendarDiscountReducer,
    manageCalendarSpecPrice: ManageCalendarSpecPriceReducer,
    manageCalendarPostPrice: ManageCalendarPostSpecPriceReducer,
    manageFiltersCalendar: ManageFiltersCalendarReducer,
    logoutRed: LogoutReducer,
    token: TokenReducer,
    registration: RegistrationReducer,
    registrationError: ErrorRegistrationReducer,
    logged: LoggedStatusReducer,
    loggedError: loggedErrorReducer,
    authLoading: AuthLoadingReducer,
})

export default createRootReducer
