import * as React from 'react';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getHousesWithFiltersManageCalendar } from '../../../Actions/ManageCalendarActions';
// import useComponentVisible from '../../../scripts/useComponentVisible.js';
import './styles/ManageCalendarSearchFilters.sass'

type IHouse = [{
  age: number,
  bedroom: number,
  booking_update: string,
  price_comment: string,
  count: number,
  count_budni: number,
  count_weekend: number,
  id: number,
  name: string,
  parameters_model: [{
    id: number,
    parameter: string,
    rus_version: string,
  }], // нужно тоже расписать
  price_budni: number,
  price_weekend: number,
  zalog: string
}];

type getDiscount = {
  day: number,
  five: number,
  four: number,
  house: number,
  house_id: number,
  house_name: string,
  id: number,
  month: number,
  more: number,
  one: number,
  oneweek: number,
  seven: number,
  six: number,
  three: number,
  threeweek: number,
  town: string,
  two: number,
  twoweek: number,
  zero: number
}

interface IAppState {
  discounts: getDiscount[],
  town: string,
  actualMonth: number,
  actualYear: number,
  houses: IHouse,
}

interface IAdditionalFilterState {
  бассейн: boolean,
  сауна: boolean,
  баня: boolean,
  веники: boolean,
  бильярд: boolean,
  билрус: boolean,
  билам: boolean,
  теннис: boolean,
  уличный: boolean,
  водоем: boolean,
  джакузи: boolean,
}

interface IFilterMainState {
  town: string,
  arrive: string,
  away: string,
  people: number,
  price: string,
  parametrs: IAdditionalFilterState,
  bed: number,
  bedroom: number,
  mkad: number,
  age: number,
}

type TPriceState = {
  costFrom: number,
  costTo: number,
}

interface IProps {
  appState: IAppState,
  getHousesWithFiltersManageCalendar(data: object): void,
  selectedDateState: SelectedDate,
  setSelectedDateState: React.Dispatch<React.SetStateAction<SelectedDate>>,
  setFlagState: React.Dispatch<React.SetStateAction<boolean>>,
  tokenState: {
    access: string,
    refresh?: string,
  }
  loggedState: {
    logged: boolean,
  }
}

type SelectedDate = {
  arrive: string,
  away: string,
}

const ManageCalendarSearchFilters: React.FC<IProps> = ({ appState, getHousesWithFiltersManageCalendar, selectedDateState, setSelectedDateState, loggedState, tokenState, setFlagState }) => {
  const [priceState, setPriceState] = useState<TPriceState>({
    costFrom: 0,
    costTo: 1000000,
  })
  const [additionalFilterState, setAdditionalFilterState] = useState<IAdditionalFilterState>({
    бассейн: false,
    сауна: false,
    баня: false,
    веники: false,
    бильярд: false,
    билрус: false,
    билам: false,
    теннис: false,
    уличный: false,
    водоем: false,
    джакузи: false,
  })
  const [filterMainState, setFilterMainState] = useState<IFilterMainState>({
    town: 'Казань',
    arrive: '',
    away: '',
    people: 0,
    price: 'от 0 до 1000000',
    parametrs: additionalFilterState,
    bed: 0,
    bedroom: 0,
    mkad: 0,
    age: 0,
  })
  const [isComponentVisible, setIsComponentVisible] = useState(() => false) // состояния открытия/закрытия параметров
  const toggling = () => setIsComponentVisible(!isComponentVisible); //  действие: открытие/закрытие списка параметров

  const handleChange = (event: any) => {
    setAdditionalFilterState({ ...additionalFilterState, [event.target.name]: event.target.value == 'on' ? true : false });
  };
  const handleChangeMainState = (event: any) => {
    setFilterMainState({ ...filterMainState, [event.target.name]: event.target.value });

  };
  console.log(selectedDateState)

  const handleMainChange = (event: any) => {
    setFilterMainState({ ...filterMainState, [event.target.name]: event.target.value })
    setSelectedDateState({ ...selectedDateState, [event.target.name]: event.target.value });
  }
  const handlePriceChange = (event: any) => {
    setPriceState({ ...priceState, [event.target.name]: event.target.value })
  }

  //Формируется запрос, добавляется дата и генерируется хэш код.
  const fillParams = () => {
    setFilterMainState({ ...filterMainState, parametrs: additionalFilterState, price: `${priceState.costTo}` })
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (!!tokenState.access && tokenState.access?.length > 0 && loggedState.logged) {
      getHousesWithFiltersManageCalendar(filterMainState);
      setFlagState(() => true);
    }
    else {
      alert('Вы не вошли');
    }
  };

  useEffect(() => {
    setFilterMainState({ ...filterMainState, town: appState.town })
  }, [appState])
  return (
    <form className="manageCalendarSearchFilters" onSubmit={handleSubmit}>
      <div className="datesCostsWrapper">
        <div className="arriveAway_settings">
          <div className="inptWrapper"><div className="prompt"><span>Дата заезда:</span></div><input required value={filterMainState.arrive} type="date" name="arrive" onChange={handleMainChange} /></div>
          <div className="inptWrapper"><div className="prompt"><span>Дата выезда:</span></div><input required value={filterMainState.away} type="date" name="away" onChange={handleMainChange} /></div>
        </div>

        <div className="costInterval">
          <div className="inptWrapper"><div className="prompt"><span>Цена от:</span></div><input type="number" name="costFrom" value={priceState.costFrom} onChange={handlePriceChange} /></div>
          <div className="inptWrapper"><div className="prompt"><span>Цена до:</span></div><input type="number" name="costTo" value={priceState.costTo} onChange={handlePriceChange} /></div>
        </div>
      </div>

      <div className="parametrs">
        <div className="DropDownContainer">
          <button className="DropDownHeader parametrs_button" type="button" onClick={toggling}>
            ПАРАМЕТРЫ
          </button>
          {isComponentVisible && (
            <div className="DropDownListContainer">
              <ul className="DropDownList Dropdown-first-column">
                <li className="toggle-container input-container">
                  <span>Кол-во человек</span>
                  <input type="number" value={filterMainState.people} onChange={handleChangeMainState} name="people" id="people" className="input-container_input" />
                </li>
                <li className="toggle-container input-container">
                  <span>Возраст</span>
                  <input type="number" value={filterMainState.age} onChange={handleChangeMainState} name="age" id="age" className="input-container_input" />
                </li>
                <li className="toggle-container input-container">
                  <span>МКАД</span>
                  <input type="number" value={filterMainState.mkad} onChange={handleChangeMainState} name="mkad" id="mkad" className="input-container_input" />
                </li>
                <li className="toggle-container">
                  <span>Бассейн</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.бассейн} onChange={handleChange} name="бассейн" id="pool" className="toggle-container__toggle" />
                  <label htmlFor="pool" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
                <li className="toggle-container">
                  <span>Сауна</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.сауна} onChange={handleChange} name="сауна" id="sauna" className="toggle-container__toggle" />
                  <label htmlFor="sauna" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
                <li className="toggle-container">
                  <span>Баня</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.баня} onChange={handleChange} name="баня" id="banya" className="toggle-container__toggle" />
                  <label htmlFor="banya" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
                <li className="toggle-container">
                  <span>Веники</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.веники} onChange={handleChange} name="веники" id="veniki" className="toggle-container__toggle" />
                  <label htmlFor="veniki" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
                <li className="toggle-container">
                  <span>Бильярд</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.бильярд} onChange={handleChange} name="бильярд" id="billiard" className="toggle-container__toggle" />
                  <label htmlFor="billiard" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
              </ul>
              <ul className="DropDownList">
                <li className="toggle-container input-container">
                  <span>Спальных комнат</span>
                  <input type="number" value={filterMainState.bedroom} onChange={handleChangeMainState} name="bedroom" id="bedroom" className="input-container_input" />
                </li>
                <li className="toggle-container input-container">
                  <span>Спальных мест</span>
                  <input type="number" value={filterMainState.bed} onChange={handleChangeMainState} name="bed" id="bed" className="input-container_input" />
                </li>
                <li className="toggle-container">
                  <span>Русский бильярд</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.билрус} onChange={handleChange} name="билрус" id="bilrus" className="toggle-container__toggle" />
                  <label htmlFor="bilrus" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
                <li className="toggle-container">
                  <span>Американский бильярд</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.билам} onChange={handleChange} name="билам" id="bilam" className="toggle-container__toggle" />
                  <label htmlFor="bilam" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
                <li className="toggle-container">
                  <span>Теннис</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.теннис} onChange={handleChange} name="теннис" id="tennis" className="toggle-container__toggle" />
                  <label htmlFor="tennis" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
                <li className="toggle-container">
                  <span>Уличный бассейн</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.уличный} onChange={handleChange} name="уличный" id="ulichniy" className="toggle-container__toggle" />
                  <label htmlFor="ulichniy" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
                <li className="toggle-container">
                  <span>Водоем</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.водоем} onChange={handleChange} name="водоем" id="vodoem" className="toggle-container__toggle" />
                  <label htmlFor="vodoem" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
                <li className="toggle-container">
                  <span>Джакузи</span>
                  <input type="checkbox" defaultChecked={additionalFilterState.джакузи} onChange={handleChange} name="джакузи" id="djakuzi" className="toggle-container__toggle" />
                  <label htmlFor="djakuzi" className="toggle-container__label">
                    <div className="ball">
                    </div>
                  </label>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
      <button className="filters_search_button" type="submit" onClick={() => fillParams()}>ПОИСК</button>
    </form>
  );
}
const mapStateToProps = (state: any) => {
  return {
    tokenState: state.token,
    loggedState: state.logged,
  };
};
const mapDispatchToProps = {
  getHousesWithFiltersManageCalendar,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCalendarSearchFilters);
