import * as React from 'react';
import './styles/manageNavbar.sass';

type Props = {
  swapSideBarState: boolean,
  setSwapSideBarState: React.Dispatch<React.SetStateAction<boolean>>;
};

const ManageNavbar: React.FC<Props> = ({swapSideBarState, setSwapSideBarState}) => {

  const swapSideBar = (): void => {
    setSwapSideBarState(!swapSideBarState);
  }
  return (
    <nav className="manageNavbar">
      <div className="navbar-left navbar-section">
        <div className="navbar-brand">
          <h2>Vtripe</h2>
        </div>
        <div className="navbar-tools">
          <ul className="tools-list">
            <button className="tools-list__item" onClick={() => swapSideBar()}><img src="/images/manageCalendar/navbar/icons/swap.svg" alt="swap" /></button>
            {/* <li className="tools-list__item"><img src="/images/manageCalendar/navbar/icons/email.svg" alt="email" /></li>
            <li className="tools-list__item"><img src="/images/manageCalendar/navbar/icons/apps.svg" alt="apps" /></li> */}
          </ul>
        </div>
      </div>
      {/* <div className="navbar-right navbar-section">
        <div className="navbar-links">
          <ul className="links-list">
            <li className="links-list__item"><img src="/images/manageCalendar/navbar/icons/search.svg" alt="search" /></li>
            <li className="links-list__item"><img src="/images/manageCalendar/navbar/icons/notifications.svg" alt="notifications" /></li>
            <li className="links-list__item"><img src="/images/manageCalendar/navbar/icons/flag.svg" alt="flag" /></li>
            <li className="links-list__item"><img src="/images/manageCalendar/navbar/icons/power.svg" alt="power" /></li>
            <li className="links-list__item"><img src="/images/manageCalendar/navbar/icons/settings.svg" alt="settings" /></li>
          </ul>
        </div>
      </div> */}
    </nav>
  );
}

export { ManageNavbar }
