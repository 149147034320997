import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { postToken } from '../../../../Actions/RegistrationAction';
import { history } from '../../../../Store/Store.js';
import { useCookies } from 'react-cookie';
import './styles/sign-in.sass';

type PropsType = {
	postToken(params: LoginStateType): void,
	token: TokenType,
	loggedError: boolean,
	loggedStatus: {
		logged: boolean,
	}
}

type TokenType = {
	access: string,
	refresh: string,
}


type LoginStateType = {
	username: string,
	password: string,
}

const SignIn: React.FC<PropsType> = ({ postToken, token, loggedError, loggedStatus }) => {
	const [cookies, setCookie] = useCookies(['refresh']);
	const initialLogin: LoginStateType = {
		username: '',
		password: '',
	}
	const initialToken = {
		access: '',
		refresh: '',
	}

	const [loginState, setLoginState] = useState(() => initialLogin);
	const [passwordShowingState, setPasswordShowingState] = useState(() => false)
	const handleLoginClick = () => {
		if (loginState.username.length > 0 && loginState.password.length > 0) {
			postToken(loginState);
		}
	}


	const handlePasswordShowingClick = () => {
		setPasswordShowingState(!passwordShowingState)
	}
	useEffect(() => {
		if (!!token.refresh && (!cookies.refresh || token.refresh !== cookies.refresh)) {
			setCookie('refresh', token.refresh, { path: '/' })
		}
		if (loggedStatus.logged && !!history.location.state && history.location.state !== null) {
			console.log(`${history.location.state}`)
			history.push(`${history.location.state}`);
		}
		else if (loggedStatus.logged) {
			history.push('/');
			console.log(cookies)
		}
	}, [token, loggedStatus.logged])

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
		setLoginState({ ...loginState, [value]: e.target.value })
	}
	return (
		<>
			<div className="sign-in-wrap">
				<button className="back-btn" onClick={() => history.goBack()}>
					<img src="/images/navbar/back-icon.svg" alt="назад" />
				</button>
				<div className="sign-in-form">
					{/* <button className="exit-btn" onClick={() => setModalActive(false)}>
						<img src="/images/sign/exit-icon-black-edition.svg" alt="закрыть" />
					</button> */}
					<div className="header-wrap">
						<div className="info-wrap">
							<div className="header-text">
								<p>Войти</p>
							</div>
							<NavLink to={`/sign_up`} className="subheader-text">
								<span className='span_or'>или</span><span className='span_sign-up'>Зарегистрироваться</span>
							</NavLink>
						</div>
						{/* <div className="user">
							<a href="#">Я собственник</a>
						</div> */}
					</div>
					<input type="text" name="login" className="e-mail-input" placeholder="Логин" onChange={(e) => handleInputChange(e, 'username')} value={loginState.username} />
					<div className="password-wrapper">
						<input type={!passwordShowingState ? 'password' : 'text'} name="password" className="password-input" placeholder="Пароль" onChange={(e) => handleInputChange(e, 'password')} value={loginState.password} />
						<span className="second-password-show" onClick={() => handlePasswordShowingClick()}><img src={`/images/sign/eye-${!passwordShowingState ? 'off' : 'on'}.svg`} alt='eye'></img></span>
					</div>
					<button className="proceed-btn" onClick={() => handleLoginClick()}>Продолжить</button>
					{/* <ul className="links-list">
						<li className="links">
							<a href="#">Войти с помощью Google</a>
						</li>
						<li className="links">
							<a href="#">Войти с помощью Facebook</a>
						</li>
					</ul> */}
					{loggedError ? <h3 className="error-notification">Неверный логин или пароль</h3> : null}
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state: any) => {
	return {
		token: state.token,
		loggedError: state.loggedError.error,
		loggedStatus: state.logged,
	};
};
const mapDispatchToProps = {
	postToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
