import * as React from 'react';
import './styles/DicsountContent.sass';

type getDiscount = {
    day: number,
    five: number,
    four: number,
    house: number,
    house_id: number,
    house_name: string,
    id: number,
    month: number,
    more: number,
    one: number,
    oneweek: number,
    seven: number,
    six: number,
    three: number,
    threeweek: number,
    town: string,
    two: number,
    twoweek: number,
    zero: number
}


type Discount = {
    show: boolean,
    age: number,
    bed: number,
    bedroom: number,
    price_comment: string,
    count: number,
    count_budni: number,
    count_weekend: number,
    id: number,
    name: string,
    discounts: getDiscount[],
    parameters_model: [{
        id: number,
        parameter: string,
        rus_version: string,
    }] // нужно тоже расписать
    price_budni: number,
    price_weekend: number,
    zalog: string
}


interface IProps {
    discountsState: Discount,
}


const DiscountContent: React.FC<IProps> = ({ discountsState }) => {
    return (
        <div className="discountContent">
            <div className="discountContent__parametrs">
                <ul className="parametersList">
                    {discountsState.parameters_model.map((elem) =>
                        <li className="parametersList__item"><span>{elem.rus_version}</span></li>
                    )}
                </ul>
                <ul className="parametersList_condition"></ul>
                <li className="parametersList_condition__item">
                    <span>Вместимость</span><span>{discountsState.count}</span>
                </li>
                <li className="parametersList_condition__item">
                    <span>Спален </span><span>{discountsState.bedroom}</span>
                </li>
                <li className="parametersList_condition__item">
                    <span>Спальных мест</span><span>{discountsState.bed}</span>
                </li>
                <li className="parametersList_condition__item">
                    <span>Залог</span><span>{discountsState.zalog}</span>
                </li>
                <li className="parametersList_condition__item">
                    <span>Возраст</span><span>{discountsState.age}</span>
                </li>
            </div >
            <div className="discountContent__comment">
                <span>{`Комментарий: ${discountsState.price_comment}`}</span>
            </div>
            {
                discountsState.discounts.length > 0 ?
                    <div className="discountContent__price-list">
                        <ul className="discountPrice_first-column">
                            {
                                discountsState.discounts.map((disc) => {
                                    switch (disc.day) {
                                        case 1: return <li className="day_1"><span className="price-title">Будни:</span>{disc.more}</li>
                                        case 5: return <li className="day_5"><span className="price-title">Пт:</span>{disc.more}</li>
                                        case 6: return <li className="day_6"><span className="price-title">Сб:</span>{disc.more}</li>
                                        case 7: return <li className="day_7"><span className="price-title">Вс:</span>{disc.more}</li>
                                        default: return null
                                    }
                                })
                            }
                        </ul>
                        <ul className="discountPrice_second-column">
                            {
                                discountsState.discounts.map((disc) => {
                                    switch (disc.day) {
                                        case 11: return <li className="day_11"><span className="price-title">Будни+Будни:</span>{disc.more}</li>
                                        case 56: return <li className="day_56"><span className="price-title">Пт+Сб:</span>{disc.more}</li>
                                        case 67: return <li className="day_67"><span className="price-title">Сб+Вс:</span>{disc.more}</li>
                                        default: return null
                                    }
                                })

                            }
                        </ul>
                    </div>
                    : null
            }
            <div className="discountContent__extra-condition">
                <ul className="extra-condition-list">
                    <li className="extra-condition-item">
                        <span>Кол-во людей в будни</span><span>{discountsState.count_budni}</span>
                    </li>
                    <li className="extra-condition-item">
                        <span>Цена за доп гостей</span><span>{discountsState.price_budni}</span>
                    </li>
                    <li className="extra-condition-item">
                        <span>Кол-во людей в выходные</span><span>{discountsState.count_weekend}</span>
                    </li>
                    <li className="extra-condition-item">
                        <span>Цена за доп гостей</span><span>{discountsState.price_weekend}</span>
                    </li>

                </ul>
            </div>
        </div >
    );
}

export { DiscountContent };