import axios from "axios";
import { getCookies } from "../scripts/getCookies";
import { refreshToken } from "./RegistrationAction";

const domen = "https://vtripe.com";

type IBooking = {
	date: string,
	id: number,
	our: boolean,
	status: string
}

type SpecPrice = {
	id: number,
	date: string,
	price: any,
}

// function getCookie(name: any) {
// 	let cookieValue: any = null;
// 	if (document.cookie && document.cookie !== "") {
// 		const cookies = document.cookie.split(";");
// 		for (let i = 0; i < cookies.length; i++) {
// 			const cookie = cookies[i].trim();
// 			// Does this cookie string begin with the name we want?
// 			if (cookie.substring(0, name.length + 1) === name + "=") {
// 				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
// 				break;
// 			}
// 		}
// 	}
// 	console.log("cookievalue: ", cookieValue);
// 	return cookieValue;
// }

function refreshTokenWithReturn(params: string) {
	console.log('зашёл в рефреш с возвратом')
	return axios
		.post(domen + "/auth/token/jwt/refresh", {
			refresh: params,
		})
}

export const getHousesBySynonymManageCalendar = (word: string, access: string) => {
	console.log(access);
	console.log("ЗАШЁЛ В ПОИСК ПО СИНОНИМУ");
	return (dispatch: any) => {
		axios
			.get(
				domen + "/erp/erp/api/v1/search/calendar",
				{
					params: {
						synonym: word,
					},
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('access')
					}
				},
			)
			.then((manageCalendar) => {
				console.log(manageCalendar);
				dispatch({
					type: "GET_SYNONYM_MANAGE_CALENDAR",
					manageCalendar,
				});
			})
			.catch((error) => {
				if (error.response.status !== 401) {
					alert('Что-то пошло не так..')
					console.log(error);
				}
			});
	};
};


export const GetAllHousesManageCalendar = (params: object, access: string) => {
	const accessTkn = localStorage.getItem("access");
	console.log("ЗАШЁЛ В ПОИСК ПОЛУЧЕНИЯ ВСЕХ ДОМОВ");
	console.log(access);
	return (dispatch: any) => {
		// Response interceptor for API calls
		axios.interceptors.request.use(
			config => {
				console.log('CONFIG', config);
				return config
			},
			error => {
				Promise.reject(error);
			}
		);
		axios.interceptors.response.use(response => response, err => {
			const status = err.response ? err.response.status : null
			if (status === 401 && err.config && !err.config.__isRetry && err.config.url !== 'https://vtripe.com/auth/token/jwt/refresh') {
				console.log(err.config.__isRetry)
				return refreshTokenWithReturn(getCookies().refresh)
					.then((token: any) => {
						console.log('mne vse ravno na catch')
						dispatch({
							type: "POST_TOKEN",
							token,
						});
						err.config.__isRetry = true;
						err.config.headers.Authorization = 'Bearer ' + token.data.access;
						return axios(err.config);
					})
					.catch(function (error: any) {
						console.log('Пора зайти в ошибку')
						dispatch({
							type: "AUTH_FAILED",
						});
						throw error;
					});

			}

			throw err;
		});
		axios
			.get(
				domen + "/erp/api/v1/show/houses",
				{
					params: params,
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('access')
					}
				},
			)

			.then((manageCalendar) => {
				console.log(manageCalendar);
				dispatch({
					type: "GET_ALL_HOUSES_MANAGE_CALENDAR",
					manageCalendar,
				});
			})
			.catch((error) => {
				if (error.response.status !== 401) {
					alert('Что-то пошло не так..')
					console.log(error);
				}
			});
	};
};

export const getHousesWithFiltersManageCalendar = (dataObject: object) => {
	console.log("ЗАШЁЛ В ПОИСК ПО ФИЛЬТРАМ");
	console.log(dataObject);
	return (dispatch: any) => {
		axios
			.get(
				domen + "/erp/erp/api/v1/search/calendar",
				{
					params: dataObject,
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('access')
					}
				},
			)
			.then((manageCalendar) => {
				console.log('зашёл в поиск по фильтрам')
				console.log(manageCalendar);
				dispatch({
					type: "GET_WITH_FILTERS_MANAGE_CALENDAR",
					manageCalendar,
				});
			})
			.catch((error) => {
				if (error.response.status !== 401) {
					alert('Что-то пошло не так..')
					console.log(error);
				}
			});
	};
};

export const GetReservedDatesManageCalendar = (params: object, access: string) => {
	console.log(access);
	return async (dispatch: any) => {
		await axios
			.get(
				domen + "/api/v1/select/mybooking",
				{
					params: params,
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('access')
					}
				},
			)
			.then((manageCalendarReservedDates) => {
				console.log(manageCalendarReservedDates)
				dispatch({
					type: "GET_RESERVED_DATES_MANAGE_CALENDAR",
					manageCalendarReservedDates,
				});
			})
			.catch((error) => {
				if (error.response.status !== 401) {
					alert('Что-то пошло не так..')
					console.log(error);
				}
			});
	};
};

export const postBookings = (params: IBooking[], access: string) => {
	console.log(access);
	console.log(params);
	return async (dispatch: any) => {
		await axios
			.post(domen + "/api/v1/edit/booking", { bookings: params }, {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('access')
				}
			})
			.then((manageCalendarBookings) => {
				dispatch({
					type: "POST_CREATE_BOOKINGS",
					manageCalendarBookings,
				});
				console.log(manageCalendarBookings);
			})
			.catch((error) => {
				if (error.response.status !== 401) {
					alert('Что-то пошло не так..')
					console.log(error);
				}
			});
	};
};


export const getDiscountManageCalendar = (params: number, access: string) => {
	console.log(access);
	console.log("вывожу айди, перед получение дискаунтов: ", params);
	return async (dispatch: any) => {
		console.log("зашёл в апишку получения дискаунтов");
		await axios
			.get(
				domen + "/erp/api/v1/getdiscount",
				{
					params: {
						id: params
					},
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('access')
					}
				}
			)
			.then((manageCalendarDiscount) => {
				console.log("дискаунт полученный: ", manageCalendarDiscount)
				dispatch({
					type: "GET_DISCOUNT_MANAGE_CALENDAR",
					manageCalendarDiscount,
				});
			})
			.catch((error) => {
				if (error.response.status !== 401) {
					alert('Что-то пошло не так..')
					console.log(error);
				}
			});
	};
}

export const GetSpecPriceManageCalendar = (params: object, access: string) => {
	console.log(access);
	return async (dispatch: any) => {
		await axios
			.get(
				domen + "/api/v1/select/specprice",
				{
					params: params,
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('access')
					}
				},

			)
			.then((manageCalendarSpecPrice) => {
				console.log(manageCalendarSpecPrice)
				dispatch({
					type: "GET_SPECPRICE_MANAGE_CALENDAR",
					manageCalendarSpecPrice,
				});
			})
			.catch((error) => {
				if (error.response.status !== 401) {
					alert('Что-то пошло не так..')
					console.log(error);
				}
			});
	};
};


export const postSpecPrice = (params: SpecPrice, access: string) => {
	console.log(access);
	console.log(params);
	return async (dispatch: any) => {
		await axios
			.post(domen + "/api/v1/edit/specprice", { specprices: [params] }, {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('access')
				}

			})
			.then((manageCalendarPostPrice) => {
				dispatch({
					type: "POST_SPEC_PRICE",
					manageCalendarPostPrice,
				});
				console.log(manageCalendarPostPrice);
			})
			.catch((error) => {
				if (error.response.status !== 401) {
					alert('Что-то пошло не так..')
					console.log(error);
				}
			});
	};
};