import { ConnectedRouter } from 'connected-react-router';
import * as React from 'react';
import { CookiesProvider } from 'react-cookie';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app/App.jsx';
import { appStore, history } from './Store/Store.js';

ReactDOM.render(
    <Provider store={appStore}>
        <ConnectedRouter history={history}>
            <CookiesProvider>
                <App />
            </CookiesProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
