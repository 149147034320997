import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { getHousesList, getHousesListWithFilters } from "../../../Actions/Actions.js";
import { getAllUrlParams } from "../../../scripts/getAllUrlParams.js";
import "./styles/filters.sass";
import { history } from "../../../Store/Store.js";
import { FilterConvertDate } from "./filter-convert-date.js";

const Filters = ({ setActive, appState, setAppState, startPrice, setStartPrice, finishPrice, setFinishPrice, getHousesListWithFilters}) => {
	const dispatch = useDispatch();
	let order;
	let s;
	let hashCode;
	const [checked, setChecked] = useState(false);
	const handleChange = (id) => {
		document.getElementById(id).checked = !document.getElementById(id).checked;
		setAppState({ ...appState, [id]: document.getElementById(id).checked });
	};
	const handleInputChange = (event) => {
		const { name, value } = event.currentTarget;
		setAppState({ ...appState, [name]: value });
	};
	const handleAfterAmountChange = (event) => {
		setFinishPrice(event.currentTarget.value)
	}
	const handleBeforeAmountChange = (event) => {
		setStartPrice(event.currentTarget.value)
	}
	let list, index;
		list = document.Switch;
	function useOutsideAlerter(ref) {
		useEffect(() => {
			function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) {
					setActive(false);
				}
			}
			// Bind the event listener
			document.addEventListener("mousedown", handleClickOutside);
			return () => {
				// Unbind the event listener on clean up
				document.removeEventListener("mousedown", handleClickOutside);
			};
		}, [ref]);
	}
	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef);

	function generateHash(string) {
		var hash = 0;
		if (string.length == 0) return hash;
		for (let i = 0; i < string.length; i++) {
			var charCode = string.charCodeAt(i);
			hash = (hash << 7) - hash + charCode;
			hash = hash & hash;
		}
		return hash;
	}
	const fillParams = () => {
		order = "заезд "+ FilterConvertDate(appState.arrive) +" выезд "+FilterConvertDate(appState.away)+" вместимость "+ appState.people;
		s = order + new Date();
		hashCode = generateHash(s)
		if (Number(startPrice) >= 0 && Number(finishPrice) >= 0)
		{
			if (Number(startPrice) > Number(finishPrice)) {
				setAppState({...appState, hash: String(hashCode), amount: ""})
			}
			else
			{
				setAppState({...appState, hash: String(hashCode), amount: "от "+Number(startPrice)+" до "+Number(finishPrice)})
			}
		}
		else
		{
			setAppState({...appState, hash: String(hashCode), amount: ""})
		}
		console.log("стейт, отправленный с фильтров ", appState);
		
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		if (appState.hash != "" && appState.arrive && appState.away) {
			console.log("Отправляю:!!!!!", appState)
			getHousesListWithFilters(appState);
			history.push({
				pathname: '/homes',
				search: '?aid='+appState.hash,
			});
		};
		setActive(false);
	};

	return (
		<>
		{appState.length != 0 ?
			<div className="filters" ref={wrapperRef}>
				<form className="filter-card" onSubmit={handleSubmit}>
					<div className="filter-header-text">
						<p>фильтры</p>
					</div>
					<div className="filter-sub-cost">
						<p>Цена</p>
						<div className="filter-sub-cost__interval from">
							<label htmlFor="cost-from">от</label>
							{startPrice == 0 ? <input type="number" value={startPrice} onChange={handleBeforeAmountChange}/> : <input type="number" value={startPrice} onChange={handleBeforeAmountChange}/>}
							
						</div>
						<div className="filter-sub-cost__interval to">
							<label htmlFor="cost-to">до</label>
							{finishPrice == 0 ? <input type="number" value={finishPrice} onChange={handleAfterAmountChange}/> : <input type="number" value={finishPrice} onChange={handleAfterAmountChange}/>}
						</div>
					</div>
					<button className="exit-icon" onClick={() => setActive(false)}>
						<img src="images/houseslist/icons/filters/exit-icon.svg" alt="exit" />
					</button>
					<div className="filter-subheader-text">
						<p>Условия</p>
					</div>
					<div className="card-column-wrap">
						<div className="filter-card-main first-column">
							<div className="filters-wrap">
								<div className="filters-wrap__header">
									<p>В доме</p>
								</div>
								<div className="filters-wrap__main">
									<div className="filter-item">
										<div className="filter-icon">
											<img src="images/houseslist/icons/filters/inHouse/beds.svg" alt="кровать" />
										</div>
										<p>кол-во спальных мест</p>
										<input type="number" value={appState.bed} name="bed" onChange={handleInputChange}/>
									</div>
									<div className="filter-item">
										<div className="filter-icon">
											<img src="images/houseslist/icons/filters/inHouse/beds.svg" alt="кровать" />
										</div>
										<p>кол-во спальных комнат</p>
										<input type="number" value={appState.bedroom} name="bedroom" onChange={handleInputChange}/>
									</div>
									<div className="filter-item">
										<div className="filter-icon">
											<img src="images/houseslist/icons/filters/inHouse/wifi.svg" alt="WI-FI" />
										</div>
										<p>WI-FI</p>
										{(window.screen.width >= 426 && window.screen.width <= 767) ? <Switch
											className="react-switch"
											checked={appState.wifi}
											onChange={() => handleChange('wifi')}
											handleDiameter={8}
											height={10}
											width={18}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="wifi"
										/> : <Switch
											className="react-switch"
											checked={appState.wifi}
											onChange={() => handleChange('wifi')}
											handleDiameter={13}
											height={16}
											width={30}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="wifi"
										/> }
									</div>
									<div className="filter-item">
										<div className="filter-icon">
											<img src="images/houseslist/icons/filters/inHouse/pool.svg" alt="бассейн" />
										</div>
										<p>бассейн</p>
										{(window.screen.width >= 426 && window.screen.width <= 767) ? <Switch
											className="react-switch"
											onChange={() => handleChange('pool')}
											checked={appState.pool}
											handleDiameter={8}
											height={10}
											width={18}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="pool"
										/> : <Switch
											className="react-switch"
											onChange={() => handleChange('pool')}
											checked={appState.pool}
											handleDiameter={13}
											height={16}
											width={30}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="pool"
										/> }
									</div>
									<div className="filter-item">
										<div className="filter-icon">
											<img src="images/houseslist/icons/filters/inHouse/sauna.svg" alt="сауна" />
										</div>
										<p>сауна</p>
										{(window.screen.width >= 426 && window.screen.width <= 767) ? <Switch
											className="react-switch"
											onChange={() => handleChange('sauna')}
											checked={appState.sauna}
											handleDiameter={8}
											height={10}
											width={18}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="sauna"
										/> : <Switch
											className="react-switch"
											onChange={() => handleChange('sauna')}
											checked={appState.sauna}
											handleDiameter={13}
											height={16}
											width={30}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="sauna"
										/> }
									</div>
									<div className="filter-item">
										<div className="filter-icon">
											<img src="images/houseslist/icons/filters/inHouse/bathhouse.svg" alt="баня" />
										</div>
										<p>баня</p>
										{(window.screen.width >= 426 && window.screen.width <= 767) ? <Switch
											className="react-switch"
											onChange={() => handleChange('venic')}
											checked={appState.venic}
											handleDiameter={8}
											height={10}
											width={18}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="venic"
										/> : <Switch
											className="react-switch"
											onChange={() => handleChange('venic')}
											checked={appState.venic}
											handleDiameter={13}
											height={16}
											width={30}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="venic"
										/> }
									</div>
									<div className="filter-item">
										<div className="filter-icon">
											<img
												src="images/houseslist/icons/filters/inHouse/billiards.svg"
												alt="бильярд"
											/>
										</div>
										<p>бильярд</p>
										{(window.screen.width >= 426 && window.screen.width <= 767) ? <Switch
											className="react-switch"
											onChange={() => handleChange('bilard')}
											checked={appState.bilard}
											handleDiameter={8}
											height={10}
											width={18}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="bilard"
										/> : <Switch
											className="react-switch"
											onChange={() => handleChange('bilard')}
											checked={appState.bilard}
											handleDiameter={13}
											height={16}
											width={30}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="bilard"
										/> }
									</div>
								</div>
							</div>
						</div>
						<div className="filter-card-main second-column">
							<div className="filters-wrap">
								<div className="filters-wrap__header">
									<p>На территории</p>
								</div>
								<div className="filters-wrap__main">
									<div className="filter-item">
										<div className="filter-icon">
											<img
												src="images/houseslist/icons/filters/inTerritory/pavilion.svg"
												alt="беседка"
											/>
										</div>
										<p>беседка</p>
										{(window.screen.width >= 426 && window.screen.width <= 767) ? <Switch
											className="react-switch"
											onChange={() => handleChange('besedka')}
											checked={appState.besedka}
											handleDiameter={8}
											height={10}
											width={18}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="besedka"
										/> : <Switch
											className="react-switch"
											onChange={() => handleChange('besedka')}
											checked={appState.besedka}
											handleDiameter={13}
											height={16}
											width={30}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="besedka"
										/> }
									</div>
									<div className="filter-item">
										<div className="filter-icon">
											<img
												src="images/houseslist/icons/filters/inTerritory/pool.svg"
												alt="беседка"
											/>
										</div>
										<p>водоём</p>
										{(window.screen.width >= 426 && window.screen.width <= 767) ? <Switch
											className="react-switch"
											onChange={() => handleChange('river')}
											checked={appState.river}
											handleDiameter={8}
											height={10}
											width={18}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="river"
										/> : <Switch
											className="react-switch"
											onChange={() => handleChange('river')}
											checked={appState.river}
											handleDiameter={13}
											height={16}
											width={30}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="river"
										/> }
									</div>
									<div className="filter-item">
										<div className="filter-icon">
											<img
												src="images/houseslist/icons/filters/inTerritory/tennis.svg"
												alt="теннис"
											/>
										</div>
										<p>теннис</p>
										{(window.screen.width >= 426 && window.screen.width <= 767) ? <Switch
											className="react-switch"
											onChange={() => handleChange('tennis')}
											checked={appState.tennis}
											handleDiameter={8}
											height={10}
											width={18}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="tennis"
										/> : <Switch
											className="react-switch"
											onChange={() => handleChange('tennis')}
											checked={appState.tennis}
											handleDiameter={13}
											height={16}
											width={30}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="tennis"
										/> }
									</div>
								</div>
							</div>
							<div className="filters-wrap conditions">
								<div className="filters-wrap__header">
									<p>Особые условия</p>
								</div>
								<div className="filters-wrap__main">
									<div className="filter-item">
										<div className="filter-icon">
											<img
												src="images/houseslist/icons/filters/conditions/pets.svg"
												alt="животное"
											/>
										</div>
										<p>можно с животными</p>
										{(window.screen.width >= 426 && window.screen.width <= 767) ? <Switch
											className="react-switch"
											onChange={() => handleChange('animal')}
											checked={appState.animal}
											handleDiameter={8}
											height={10}
											width={18}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="animal"
										/> : <Switch
											className="react-switch"
											onChange={() => handleChange('animal')}
											checked={appState.animal}
											handleDiameter={13}
											height={16}
											width={30}
											onColor="#fff"
											offColor="#fff"
											uncheckedIcon={false}
											checkedIcon={false}
											borderRadius={30}
											onHandleColor="#FFFDFD"
											offHandleColor="#FFFDFD"
											id="animal"
										/> }
									</div>
								</div>
							</div>
						</div>
					</div>
					<button className="search-btn" type="submit" onClick={() => fillParams()}>
						Найти
					</button>
				</form>
			</div>
			: null }
		</>
	);
};

const mapDispatchToProps = {
	getHousesListWithFilters
};

export default connect(null, mapDispatchToProps)(Filters);

