import { reverse } from "node:dns/promises";
import * as React from "react";

const FilterConvertDate = (value) =>
{
    const date = value.split('-');
    const newDate = date.reverse();
    const result = date[0] + "." + date[1] + "." + date[2];
    return result;
}

export {FilterConvertDate};